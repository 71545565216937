import {
  Alert, Box, DialogActions, DialogContentText, FormControl, Stack, ThemeProvider, styled, Divider,
  Typography, lighten, DialogActionsProps, FormLabel, MobileStepper, Accordion, AccordionSummary,
  AccordionDetails, Button,
} from "@mui/material";
import { useForm, Controller, Control, useWatch } from 'react-hook-form';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Meeting, MeetingSlot, MeetingHoldEventError, MeetingUpdate, MeetingCreate, SchedulingPreferencesResponse,
  RecurringTimes, MeetingPriority, InvalidParticipantVotes, CabinetFeatures, 
  AllOAuthGrantDetails, User, LeaderList, Calendar, MeetingRoom, PresetLocations, ZoomSettings,
  ParticipantAutocompleteOption, MeetingLeader, Leader, MeetingStatus,
  PrivateExternalParticipant
} from "../../../store";
import { ExcludedSlotInfo, getSlotDuration, getTemplateVars, TimeZone } from "../../../utils/scheduleUtils";
import {
  CabDropdown, CabTextInput, CabTextTokenInput, CabList, CabSwitch, CabButton, CabIcon, 
  CabTooltip, CabPanel, CabToggleChip,
} from "@CabComponents";
import { CabControlLabel } from "@CabComponents/CabControlLabel";
import { CabDurationPicker } from "@CabComponents/CabDurationPicker";
// import { CabDurationPickerDropdown as CabDurationPicker } from "@CabComponents/CabDurationPickerDropdown";
import TimeSlotChips from "./TimeSlotChips";
import { theme, darkTheme } from "../../../theme";
import CabSpinner from "@CabComponents/CabSpinner";
import colors from "../../../colors";
import { CabComponentProps } from "@CabComponents/cabStyled";
import { DependentController, useAutoSubmit } from "../../../utils/formUtils";
import { CabModal } from "@CabComponents/CabModal";
import { useDeepCompareEffect } from "react-use";
import ExecutiveHoldAlert from "./ExecutiveHoldAlert";
import QuestionChips from "./QuestionChips";
import QuestionModal from "./QuestionModal";
import PollUpdateModal from '../PollUpdateModal/PollUpdateModal';
import {
  transformMarkupToSimpleTemplate, transformMarkupToText, transformSimpleTemplateToMarkup,
} from "@CabComponents/CabTextTokenInput";
import { CabTextTokenDisplay } from "@CabComponents/CabTextTokenDisplay";
import { ACCEPTED_MIME_EXTS, MEETING_TYPE, PROVIDER } from "../../../constants";
import RecurringRangeModal from "../ReusableBooking/RecurringRangeModal";
import RecurringDayChips from "./RecurringDayChips";
import ExecutiveHoldControl from "./ExecutiveHoldControl";
import EditMeetingInfoModal from "./EditMeetingInfoModal";
import { checkForMicrosoftGrant } from "../../../utils/authUtils";
import CalendarAndLocation from "./CalendarAndLocation";
import MeetingParticipants from "./MeetingParticipants";
import EditParticipantsModal from "../EditParticipantsModal";
import { 
  IoChevronDown, IoInformationCircleOutline, IoHelpCircleOutline, IoPencil, IoAddOutline, IoCalendarOutline, 
  IoPencilOutline, IoDocumentOutline, IoBookmarkOutline, IoLockClosed 
} from "react-icons/io5";


const formatDuration = (val: number) => `${Math.floor(val / 60)}:${(val % 60).toString().padStart(2, '0')}`;


const StyledCabDurationPicker = styled(CabDurationPicker, { label: "StyledCabDurationPicker" })({
  marginTop: "8px"
});

export interface MeetingSettingsProps {
  meeting: Partial<Meeting> & { id: Meeting['id'] };
  meetingSlots: MeetingSlot[];
  leaderMap: {[key: string]: Leader}
  holdCalendarOptions: { id: number; calendar_id: string; name: string, can_edit?: boolean }[];
  calendarTimezone?: TimeZone;
  secondaryTimezones?: (TimeZone | undefined)[];
  showHolds?: boolean;
  onDeleteSlots: (slots: MeetingSlot[]) => void;
  onRemoveLeader: (meetingLeaderId: MeetingLeader['id']) => void;
  onShowErrors: (show: boolean) => void;
  meetingErrors: MeetingHoldEventError[];
  onSubmit: (meeting: MeetingUpdate, files?: File[]) => Promise<void>;
  onCreateNewMeeting: (meeting: MeetingCreate, files?: File[],
    participants?: PrivateExternalParticipant[], meetingCreateLeaders?: MeetingLeader[]) => Promise<void>;
  onShare: () => void;
  onCancel: () => void;
  openUpgradeModal: () => void;
  loading?: boolean;
  open: boolean;
  schedulingPrefs: SchedulingPreferencesResponse;
  isOwner: boolean;
  updateExecHoldNoticePref: (value: boolean) => void;
  slotsAreRecalculating: boolean;
  pollUpdateOpen: boolean;
  pollUpdateHandlerAccept: () => void;
  pollUpdateHandlerReject: () => void;
  invalidMeetingSlots: InvalidParticipantVotes;
  dragTimeslotsText: string;
  holdTimesText: string;
  handleSaveRecurringTimes: (recurringTimes?: RecurringTimes) => void;
  onExcludedSlotsCreated: (slots: ExcludedSlotInfo[]) => void;
  onEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  deleteMeetingFile: (id: number, meetingId: number) => void;
  features?: CabinetFeatures;
  oauth_grant_details?: AllOAuthGrantDetails;
  additionalCalendars: number[];
  user: User | null | undefined;
  zoomSettings: {
    [settingsId: string]: ZoomSettings;
  };
  onAddZoom: () => void;
  leaders: LeaderList;
  leaderCalendarOptions: Calendar[];
  calendars: Calendar[];
  meetingRooms: { [id: string]: MeetingRoom };
  presetLocations: PresetLocations;
  participantAutocompleteOptions: ParticipantAutocompleteOption[];
  handleDeleteAttendee: (id: number) => void;
  meetingType: keyof typeof MEETING_TYPE;
  onCalendarTimezoneSelected?: (timezone: TimeZone) => void;
}

export interface MeetingSettingFormInput {
  meetingName: string;
  internalMeetingLabel?: string;
  description: string;
  duration: number;
  attendees: number;
  executiveHold: boolean;
  executiveHoldCalendars: number[];
  selectExactTimes: boolean;
  preventDoubleBookings: boolean;
  allowAddParticipants: boolean;
  startDelay: number;
  files: File[] | null;
  recurringTimes: RecurringTimes | null | undefined;
  selectedCalendar?: number;
  bufferStartMinutes: number;
  bufferEndMinutes: number;
}

export interface LocationsFormInput {
  locations?: ({
    conference?: { provider: number | null; leader: number | null },
    locationText?: string;
    room?: number;
    presetLocation?: number;
    none?: boolean;
  })[];
}


const getDefaultTitle = (leaders: Leader[], isReusable: boolean): string => {
  if (isReusable && leaders.length > 0) {
    let title = "";
    if (leaders.length > 1) {
      title = leaders.map(leader => leader.first_name).join(", ");
    } else if (leaders.length === 1) {
      title = leaders[0].first_name + " " + leaders[0].last_name;
    }
    title += " <> {{attendee}}";
    return title;
  } 
  return "";
};

const uploadFilesText = `Files will be attached to the event automatically when using the booking link.`;


const MeetingSettings = ({ meeting, meetingSlots, holdCalendarOptions, calendarTimezone, 
  secondaryTimezones, onDeleteSlots, onShowErrors, meetingErrors, showHolds, onSubmit, loading, onShare, open, 
  schedulingPrefs, isOwner, updateExecHoldNoticePref, onCreateNewMeeting, onCancel, openUpgradeModal,
  slotsAreRecalculating, onRemoveLeader, pollUpdateOpen, pollUpdateHandlerAccept,
  pollUpdateHandlerReject, invalidMeetingSlots, dragTimeslotsText, holdTimesText, handleSaveRecurringTimes,
  onEditSlot, onExcludedSlotsCreated, deleteMeetingFile, features, oauth_grant_details, additionalCalendars,
  user, zoomSettings, leaders, calendars, meetingRooms, presetLocations, participantAutocompleteOptions, 
  handleDeleteAttendee, leaderMap, leaderCalendarOptions, meetingType, onAddZoom, onCalendarTimezoneSelected
}: MeetingSettingsProps): ReactElement | null => {
  const [isPoll, setIsPoll] = useState(meeting.is_poll);
  const [isReusable, setIsReusable] = useState(meeting.is_reusable);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showNoSlotsWarning, setShowNoSlotsWarning] = useState(false);
  const [showSlotDurationWarning, setShowSlotDurationWarning] = useState(false);
  const [executiveHoldAlertOpen, setExecutiveHoldAlertOpen] = useState<number | null>(null);
  const [step, setStep] = useState(0);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [forceRequired, setForceRequired] = useState(false);
  const [disableSelectExactTimes, setDisableSelectExactTimes] = useState(false);
  const [openSlotChangeModal, setOpenSlotChangeModal] = useState(false);
  const [recurringRangeModalOpen, setRecurringRangeModalOpen] = useState(false);
  const [editParticipantsModalOpen, setEditParticipantsModalOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState<'info'|'holds'|'questions'|'files'|'times'|null>(null);
  const [maxFilesExceeded, setMaxFilesExceeded] = useState(false);

  const selectedLeaderIds = useMemo(() => meeting.leader_info?.map(sl => sl.id) || [], [meeting.leader_info]);

  const meetingLeaders = useMemo(() => meeting.leader_info?.map(l => ({
    ...l, id: l.meeting_leader_id, meeting: meeting.id, leader: l.id,
    required: l.required != null ? l.required : true,
    prevent_conflicts: l.prevent_conflicts != null ? l.prevent_conflicts : true,
    should_invite: l.should_invite != null ? l.should_invite : true,
    view_calendar: l.view_calendar != null ? l.view_calendar : true,
    is_fetchable: Object.values(calendars).filter(c => c.leaders.includes(l.id)).some(c => c.calendar_access_id),
  })) || [], [meeting.leader_info, meeting.id, calendars]);

  const showTokenInputs = !isPoll;
  const isSavedMeeting = meeting.id !== -1;

  const holdsScrollRef = useRef<HTMLDivElement>(null);
  const questionsScrollRef = useRef<HTMLDivElement>(null);
  const filesScrollRef = useRef<HTMLDivElement>(null);

  const hasMicrosoftGrant = useMemo(() => (
    oauth_grant_details && checkForMicrosoftGrant(oauth_grant_details)
  ), [oauth_grant_details]);

  const meetingParticipants = useMemo(() => Object.values(meeting.participants || {}), [meeting.participants]);

  const attendeeValidation = {
    number: (attendees: number): boolean => attendees >= meetingParticipants.length,
    notZero: (attendees: number): boolean => attendees > 0
  };

  const templateVars = useMemo(() => {
    // NOTE: currently we don't allow using meeting question variables prior to meeting being saved.
    // This can eventually work but will require back-end to "re-wire" question IDs used in tokens
    return getTemplateVars(meeting.id > 0 ? meeting.questions : undefined);
  }, [meeting.id, meeting.questions]);

  const templateNameLookup = useMemo(() => (
    Object.entries(templateVars)
      .map(([k, v]) => ({ [k]: v.replaceText }))
      .reduce((a, b) => ({ ...a, ...b }), {})
  ), [templateVars]);

  const locDefaultValues: LocationsFormInput = useMemo(() => {
    const locations: LocationsFormInput['locations'] = [
      ...(meeting.conference_provider && meeting.conference_provider !== PROVIDER.NO_PROVIDER.id
        ? [{ conference: { provider: meeting.conference_provider, leader: meeting.conference_leader || null } }]
        : []
      ),
      ...(meeting.rooms || []).map(roomId => ({ room: roomId })),
      ...(meeting.location_presets || []).map(presetLoc => ({ presetLocation: presetLoc })),
      ...(meeting.locations || []).map(loc => ({
        locationText: transformSimpleTemplateToMarkup(loc, templateNameLookup),
      })),
    ];

    if (locations.length === 0) {
      locations.push({ none: true });
    }

    return {
      locations,
    };
  }, [meeting.conference_leader, meeting.conference_provider, meeting.locations,
    meeting.rooms, meeting.location_presets, templateNameLookup]);

  const defaultValues: MeetingSettingFormInput = useMemo(() => {
    const defaultTitle = getDefaultTitle(
      selectedLeaderIds.map((leaderId) => leaderMap[leaderId]).filter(l => !!l), isReusable || false
    );

    return {
      meetingName: showTokenInputs
        ? transformSimpleTemplateToMarkup(meeting.title || defaultTitle, templateNameLookup)
        : meeting.title || defaultTitle,
      internalMeetingLabel: meeting.internal_label || '',
      description: showTokenInputs
        ? transformSimpleTemplateToMarkup(meeting.description || '', templateNameLookup)
        : meeting.description || '',
      duration: meeting.duration_minutes || schedulingPrefs.user_prefs?.default_duration_minutes || 30,
      attendees: meeting.num_expected_participants || meeting.leader_info?.length || 0,
      executiveHold: (meeting.hold_calendars && meeting.hold_calendars.length > 0) || false,
      executiveHoldCalendars: meeting.hold_calendars || [],
      selectExactTimes: meeting.auto_merge_slots != null ? !meeting.auto_merge_slots : false,
      preventDoubleBookings: isPoll ? meeting.prevent_conflict || false : 
        meeting.prevent_conflict !== undefined ? meeting.prevent_conflict : 
          !!schedulingPrefs.user_prefs?.booking_check_conflicts,
      allowAddParticipants: meeting.allow_add_participants !== undefined ? meeting.allow_add_participants : 
        !!schedulingPrefs.user_prefs?.default_allow_add_participants,
      startDelay: meeting.start_delay_minutes != null ? meeting.start_delay_minutes : 
        schedulingPrefs.user_prefs?.default_start_delay_minutes != null ? 
          schedulingPrefs.user_prefs?.default_start_delay_minutes : 60,
      files: null,
      recurringTimes: meeting.recurring_times || null,
      selectedCalendar: meeting.booking_calendar,
      bufferStartMinutes: meeting.buffer_start_minutes || schedulingPrefs.user_prefs?.default_buffer_start_minutes || 0,
      bufferEndMinutes: meeting.buffer_end_minutes || schedulingPrefs.user_prefs?.default_buffer_end_minutes || 0,
    };
  }, [isPoll, isReusable, leaderMap, meeting.allow_add_participants, meeting.auto_merge_slots, meeting.booking_calendar,
    meeting.buffer_end_minutes, meeting.buffer_start_minutes, meeting.description, meeting.duration_minutes, 
    meeting.hold_calendars, meeting.internal_label, meeting.leader_info?.length, meeting.num_expected_participants, 
    meeting.prevent_conflict, meeting.recurring_times, meeting.start_delay_minutes, meeting.title, 
    schedulingPrefs.user_prefs?.booking_check_conflicts, schedulingPrefs.user_prefs?.default_allow_add_participants, 
    schedulingPrefs.user_prefs?.default_buffer_end_minutes, schedulingPrefs.user_prefs?.default_buffer_start_minutes, 
    schedulingPrefs.user_prefs?.default_duration_minutes, schedulingPrefs.user_prefs?.default_start_delay_minutes, 
    selectedLeaderIds, showTokenInputs, templateNameLookup]);

  const meetingForm = useForm<MeetingSettingFormInput>({ defaultValues });
  const locationsForm = useForm<LocationsFormInput>({ defaultValues: locDefaultValues });
  const { control, handleSubmit: formSubmit, watch, reset, setValue } = meetingForm;

  const { locations } = locationsForm.watch();

  const [recurringTimes, currentCal] = watch([
    'recurringTimes', 'selectedCalendar'
  ]);

  const isAnyRecurringDayEnabled = useMemo(() => {
    return recurringTimes?.days.mon.enabled || recurringTimes?.days.tue.enabled || recurringTimes?.days.wed.enabled || 
    recurringTimes?.days.thu.enabled || recurringTimes?.days.fri.enabled || recurringTimes?.days.sat.enabled || 
    recurringTimes?.days.sun.enabled;
  }, [recurringTimes]);

  const locReset = locationsForm.reset;
  useDeepCompareEffect(() => {
    locReset(locDefaultValues);
  }, [locDefaultValues, locReset]); 

  const clearRecurringTimesAvailability = useCallback(() => {
    if (!isSavedMeeting) {
      setValue('recurringTimes', null);
      const excludedMeetingSlots = meetingSlots.filter(slot => slot.is_exclude && slot.meeting === meeting.id);
      if (excludedMeetingSlots.length > 0) {
        onDeleteSlots(excludedMeetingSlots);
      }
      handleSaveRecurringTimes();
    }
  }, [handleSaveRecurringTimes, meeting.id, isSavedMeeting, onDeleteSlots, meetingSlots, setValue]);

  const checkForWarnings = useCallback((duration: number) => {
    if (!meeting.auto_merge_slots) return;

    const slotsLessThanDuration = meetingSlots.some(
      slot => getSlotDuration(slot) < (duration || 0)
    );
    if (slotsLessThanDuration && meeting.id) {
      setShowSlotDurationWarning(slotsLessThanDuration);
    }
  // Jost for currentMeetingSlots
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting.id, meeting.auto_merge_slots, JSON.stringify(meetingSlots)]);

  const showNoWarnings = () => {
    setShowNoSlotsWarning(false);
    setShowSlotDurationWarning(false);
  };

  const handleShare = () => {
    onShare();
    showNoWarnings();
    checkForWarnings(meetingForm.getValues().duration);
  };

  const handleCancel = () => {
    setOpenEditModal(false);
    setStep(0);
    reset(defaultValues);
    onCancel();
  };

  const handleCancelEditModal = () => {
    setOpenEditModal(false);
    reset(defaultValues);
  };

  const addQuestionButtonClicked = () => {
    setOpenQuestionModal(true);
    setForceRequired(false);
  };

  const templateAddQuestionButtonClicked = () => {
    setOpenQuestionModal(true);
    setForceRequired(true);
  };


  const handlePollVotesAccept = () => {
    pollUpdateHandlerAccept();
  };

  const handlePollVotesReject = () => {
    setValue("selectExactTimes", false, { shouldDirty: true });
    pollUpdateHandlerReject();
  };

  const executeAddHoldCalendar = (cal: number) => {
    // setValue("executiveHold", true, { shouldDirty: true });
    setValue("executiveHoldCalendars", [cal], { shouldDirty: true });
    setExecutiveHoldAlertOpen(null);
  };

  const selectExactTimes = () => {
    setValue("selectExactTimes", true, { shouldDirty: true });
    setOpenSlotChangeModal(false);
  };

  const saveRecurringAvailability = (newRecurringTimes: RecurringTimes) => {
    setValue("recurringTimes", newRecurringTimes, {shouldDirty: true});
  };

  const expandSection = (section: 'info'|'holds'|'questions'|'files'|'times'|null) => {
    setExpandedSection(section);
    if (section === 'holds') {
      holdsScrollRef.current?.scrollIntoView();
    } else if (section === 'questions') {
      questionsScrollRef.current?.scrollIntoView();
    } else if (section === 'files') {
      filesScrollRef.current?.scrollIntoView();
    }
  };

  // TODO: anyway to simplify this or pull it out into util or other file?
  const handleSubmit = useCallback(async (data: MeetingSettingFormInput) => {
    showNoWarnings();
    checkForWarnings(data.duration);
    setDisableSelectExactTimes(true);
    setTimeout(() => setDisableSelectExactTimes(false), 2000);
    if (!isSavedMeeting) { // create
      await onCreateNewMeeting({
        title: showTokenInputs ? transformMarkupToSimpleTemplate(data.meetingName) : data.meetingName,
        title_booked: null,
        internal_label: isReusable
          ? (data.internalMeetingLabel || transformMarkupToText(data.meetingName))
          : undefined,
        description: showTokenInputs ? transformMarkupToSimpleTemplate(data.description) : data.description,
        description_booked: null,
        duration_minutes: data.duration,
        num_expected_participants: data.attendees > 0 ? data.attendees : 1,
        executive_hold: data.executiveHoldCalendars.length > 0,
        hold_calendars: data.executiveHoldCalendars,
        leaders: meeting.leader_info?.map(leader => leader.id) || [],
        create_date: '',
        date_scheduled: null,
        event_start_time: null,
        selected_scheduled_time: null,
        event_id: null,
        calendar_tz: calendarTimezone?.name || null,
        secondary_tz: secondaryTimezones?.map(tz => tz?.name || null) || [],
        copy_tz: null,
        secondary_copy_tz: null,
        status: MeetingStatus.PENDING,
        prevent_conflict: data.preventDoubleBookings,
        is_poll: isPoll,
        errors: [],
        error_count: 0,
        show_voter_names: true,
        allow_add_participants: data.allowAddParticipants,
        rooms: meeting.rooms || [],
        location_presets: meeting.location_presets || [],
        questions: meeting.questions || {},
        auto_merge_slots: !data.selectExactTimes,
        is_reusable: isReusable || false,
        enable_public_attendee_list: meeting.enable_public_attendee_list || false,
        allow_reschedule_cancel: schedulingPrefs.user_prefs?.allow_reschedule_cancel || false,
        recurring_times: data.recurringTimes || null,
        priority: MeetingPriority.MEDIUM,
        start_delay_minutes: data.startDelay,
        files: [],
        buffer_start_minutes: data.bufferStartMinutes,
        buffer_end_minutes: data.bufferEndMinutes,
      },
      data.files || undefined,
      meetingParticipants,
      meetingLeaders,
      );
    } else { // update
      onSubmit({
        id: meeting.id,
        title: showTokenInputs && data.meetingName != null
          ? transformMarkupToSimpleTemplate(data.meetingName)
          : data.meetingName,
        internal_label: isReusable
          ? (data.internalMeetingLabel || transformMarkupToText(data.meetingName))
          : undefined,
        description: showTokenInputs && data.description != null
          ? transformMarkupToSimpleTemplate(data.description)
          : data.description,
        duration_minutes: data.duration || meeting.duration_minutes,
        num_expected_participants: data.attendees,
        executive_hold: data.executiveHoldCalendars.length > 0,
        prevent_conflict: data.preventDoubleBookings,
        hold_calendars: data.executiveHoldCalendars,
        auto_merge_slots: data.selectExactTimes == null ? undefined : !data.selectExactTimes,
        start_delay_minutes: data.startDelay || meeting.start_delay_minutes,
        recurring_times: data.recurringTimes || null,
        //additional_calendars: additionalCalendars,
      }, data.files || undefined);
      setValue("files", [], {shouldDirty: false});
    }
    setOpenEditModal(false);
  },
  // Need to use deep compare for secondary timezones
  /* eslint-disable react-hooks/exhaustive-deps */
  [checkForWarnings, meeting.id, meeting.rooms, meeting.location_presets, meeting.enable_public_attendee_list, 
    meeting.recurring_times, meeting.duration_minutes, meeting.start_delay_minutes, onCreateNewMeeting, showTokenInputs,
    calendarTimezone?.name, JSON.stringify(secondaryTimezones), isPoll, meeting.questions, isReusable,
    schedulingPrefs.user_prefs?.allow_reschedule_cancel, onSubmit, setValue, meeting.is_reusable, additionalCalendars,
    meetingParticipants, meetingLeaders]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (open) {
      reset(defaultValues);
      setOpenEditModal(false);
      setIsPoll(meeting.is_poll);
      setIsReusable(meeting.is_reusable);
      setStep(0);
      expandSection('info');
    }
    // Only reset the form when the meeting changes or the panel opens
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting.id, open]);

  // TODO: anyway to simplify this?
  useEffect(() => {
    if (meetingType === 'ONE_OFF') {
      setIsPoll(false);
      setIsReusable(false);
      setValue('preventDoubleBookings', schedulingPrefs.user_prefs?.booking_check_conflicts || false);
      if (meeting.recurring_times) {
        clearRecurringTimesAvailability();
      }
    } else if (meetingType === 'POLL') {
      setIsPoll(true);
      setIsReusable(false);
      setValue('preventDoubleBookings', false);
      const {meetingName} = meetingForm.getValues();
      if (meetingName.includes('{{')) {
        setValue('meetingName', '');
      }
      if (meeting.recurring_times) {
        clearRecurringTimesAvailability();
      }
    } else if (meetingType === 'REUSABLE') {
      setIsReusable(true);
      setValue('preventDoubleBookings', schedulingPrefs.user_prefs?.booking_check_conflicts || false);
      setIsPoll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingType]);

  useEffect(() => {
    const {meetingName} = meetingForm.getValues();
    if (isReusable && !meetingName) {
      setValue('meetingName', defaultValues.meetingName);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReusable]);

  //when prevent conflicts is changed in share modal, update meeting side panel
  useEffect(() => {
    if (isSavedMeeting) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting.prevent_conflict]);


  const handleAutoSubmit = useCallback(async (data: MeetingSettingFormInput) => {
    showNoWarnings();
    checkForWarnings(data.duration);
    setDisableSelectExactTimes(true);
    setTimeout(() => setDisableSelectExactTimes(false), 2000);

    await onSubmit({
      id: meeting.id,
      title: showTokenInputs && data.meetingName != null
        ? transformMarkupToSimpleTemplate(data.meetingName)
        : data.meetingName,
      description: showTokenInputs && data.description != null
        ? transformMarkupToSimpleTemplate(data.description)
        : data.description,
      duration_minutes: data.duration || meeting.duration_minutes,
      num_expected_participants: data.attendees,
      executive_hold: data.executiveHoldCalendars && data.executiveHoldCalendars.length > 0,
      hold_calendars: data.executiveHoldCalendars,
      prevent_conflict: data.preventDoubleBookings,
      auto_merge_slots: data.selectExactTimes == null ? undefined : !data.selectExactTimes,
      start_delay_minutes: data.startDelay || meeting.start_delay_minutes,
      recurring_times: data.recurringTimes,
      booking_calendar: data.selectedCalendar,
    }, data.files || undefined);
    setValue("files", [], {shouldDirty: false});
    setOpenEditModal(false);
  }, [checkForWarnings, onSubmit, meeting.id, meeting.duration_minutes, meeting.start_delay_minutes,
    showTokenInputs, setValue]);

  // selectExactTimes, preventDoubleBookings, recurringTimes need to always be autoSubmit to call updateNewMeeting or
  // updateMeeting because they change slots and slot warnings, duration effects slots as well so must be autosubmit for
  // new meetings. The other fields only require auto when the meeting is saved otherwise they are handled in create.
  const autoSubmitFields: (keyof MeetingSettingFormInput)[] = 
  ['executiveHold', 'executiveHoldCalendars', 'selectExactTimes', 'preventDoubleBookings', 'recurringTimes'];
  if (!isSavedMeeting) {
    autoSubmitFields.push('duration');
  } else {
    autoSubmitFields.push('files');
    autoSubmitFields.push('selectedCalendar');
  }

  useAutoSubmit({
    control: meetingForm.control,
    memoizedHandleSubmit: handleAutoSubmit,
    fields: autoSubmitFields,
    options: {
      executiveHold: { debounce: 2000 },
      preventDoubleBookings: { debounce: 400 },
      executiveHoldCalendars: { debounce: 2000 },
      selectExactTimes: { debounce: 400 },
      reusableMeeting: { debounce: 400 },
      files: { debounce: 400 },
      recurringTimes: {debounce: 400},
      selectedCalendars: {debounce: 400},
    },
    handleSubmit: meetingForm.handleSubmit,
    getValues: meetingForm.getValues,
    reset: meetingForm.reset
  });

  useEffect(() => {
    if (isSavedMeeting && currentCal !== undefined &&
      leaderCalendarOptions.filter(cal => cal.id === currentCal).length === 0) {
      if (leaderCalendarOptions.length) {
        setValue('selectedCalendar', leaderCalendarOptions[0].id, { shouldDirty: true });
      }
    }
  }, [currentCal, isSavedMeeting, leaderCalendarOptions, setValue]);

  if (!open) return null;

  return (
    <>
      <form id="meeting-form" onSubmit={formSubmit(handleSubmit)} style={{ height: '100%' }}>
        <Box width="100%" height="100%" display={'flex'} position="relative" flexDirection="column"
          paddingLeft={1.5} paddingRight={1.5}
        >

          {/* TODO: Pull out Accordion _content_ into separate components */}

          <Stack spacing={1} flex={1}>
            {(((!isPoll && step === 0) || isSavedMeeting)
              || (isPoll && step === 0)) && (
              <>
                <Accordion elevation={0} disableGutters expanded={expandedSection === 'info'}
                  sx={{
                    backgroundColor: 'transparent',
                    '&:before': {
                      display: 'none',
                    },
                    '&.MuiAccordion-root.Mui-expanded': {
                      margin: 0
                    }
                  }}>
                  {isSavedMeeting && (
                    <AccordionSummary
                      expandIcon={<CabIcon Icon={IoChevronDown} />}
                      onClick={() => expandSection(expandedSection === 'info' ? null : 'info')}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ backgroundColor: colors.navyDark, padding: 0, minHeight: 32,
                        '& .MuiAccordionSummary-content': {
                          marginTop: 0,
                          marginBottom: 0,
                        }
                      }}
                    >
                      <Typography variant="h2" color={colors.white900} display='flex' gap={1}>
                        <CabIcon Icon={IoInformationCircleOutline} sx={{ fontSize: '20px' }} />
                        {isPoll ? 'Poll Information' : 'Meeting Information'}
                      </Typography>
                    </AccordionSummary>
                  )}
                  <Box width="100%" height="100%" display={'flex'} position="relative" 
                    flexDirection="column" gap={2} marginTop={1}>
                    {!isSavedMeeting ? (
                      <>
                        {isReusable && (
                          <>
                            <Box display="flex" flexDirection="row" gap={1} alignContent="center" width="100%">
                              <DependentController name="internalMeetingLabel" control={control}
                                dependencies={['meetingName']}
                                render={({ field: { ref, ...field }, deps: [meetingName] }) => (

                                  <CabTextInput
                                    {...field}
                                    inputRef={ref}
                                    label={'Internal Meeting Label'}
                                    placeholder={transformMarkupToText(meetingName as string)}
                                    multiline
                                    minRows={1}
                                    maxRows={3}
                                    overrides={{ InputLabelProps: { shrink: true } }}
                                    formControlSx={{ flex: 1 }}
                                    endIcon={
                                      <CabTooltip
                                        wrapWithSpan
                                        title={`This label helps you organize your reusable meetings. It will not
                                      be on any events, and your attendees will never see it.`}
                                        sx={{ alignSelf: 'center' }}
                                      >
                                        <CabIcon Icon={IoHelpCircleOutline} sx={{ fontSize: '20px' }} />
                                      </CabTooltip>
                                    }
                                  />
                                )} />
                            </Box>
                            <Divider sx={{ marginBottom: 1, marginTop: 0.5 }} />
                          </>
                        )}

                        <Controller
                          name="meetingName"
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { ref, ...field } }) => (
                            showTokenInputs ? (
                              <CabTextTokenInput
                                {...field}
                                inputRef={ref}
                                label={'Event Title'}
                                id="MeetingSettings-event-title"
                                required
                                multiline
                                templateVars={templateVars}
                                inputProps={{ style: { maxHeight: 75, minHeight: 28 } }}
                                onAddQuestion={(isSavedMeeting && (meeting.is_reusable || meeting.is_poll)) ? 
                                  () => templateAddQuestionButtonClicked()
                                  : undefined}
                              />
                            ) : (
                              <CabTextInput
                                {...field}
                                inputRef={ref}
                                label={'Event Title'}
                                id="MeetingSettings-event-title"
                                required
                                multiline
                                minRows={1}
                                maxRows={3}
                              />
                            )
                          )}
                        />
                        {!features?.DISABLE_EVENT_DESCRIPTION && (
                          <Controller
                            name="description"
                            control={control}
                            render={({ field: { ref, ...field } }) => showTokenInputs ? (
                              <CabTextTokenInput
                                {...field}
                                inputRef={ref}
                                label='Event Description'
                                id="MeetingSettings-event-description"
                                multiline
                                // minRows={3}
                                // maxRows={6}
                                templateVars={templateVars}
                                inputProps={{ style: { height: 75 } }}
                                onAddQuestion={(isSavedMeeting && (meeting.is_reusable || meeting.is_poll)) ? 
                                  () => templateAddQuestionButtonClicked()
                                  : undefined}
                              />
                            ) : (
                              <CabTextInput 
                                {...field} 
                                inputRef={ref} 
                                label='Event Description' 
                                multiline 
                                id="MeetingSettings-event-description"
                                minRows={3}
                                maxRows={6} 
                              />
                            )}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {meeting.internal_label && (
                          <>
                            <Typography variant="h1" sx={{fontSize: "20px"}}>
                              {meeting.internal_label}
                            </Typography>
                            <Divider />
                          </>
                        )}
                        <Typography variant="h1" sx={{fontSize: "20px"}}>
                          <CabTextTokenDisplay templateVars={templateVars}>
                            {meeting.title || ''}
                          </CabTextTokenDisplay>
                        </Typography>
                        {meeting.description && (
                          <Typography variant="body2" whiteSpace='pre-wrap' color="#fff" sx={
                            {
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 6,
                            }
                          }>
                            <CabTextTokenDisplay templateVars={templateVars}>
                              {meeting.description || ''}
                            </CabTextTokenDisplay>
                          </Typography>
                        )}
                      </>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <FormControl sx={{ marginBottom: 0 }}>
                        <FormLabel sx={{ marginBottom: !isSavedMeeting ? "8px" : "0px" }}>
                          Meeting Length
                        </FormLabel>
                        {!isSavedMeeting ? (
                          <Controller
                            name="duration"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              <StyledCabDurationPicker
                                {...field}
                                durationMinutes={field.value}
                                sx={{ marginBottom: "12px" }}
                              />
                            )}
                          />
                        ) : (
                          meeting.duration_minutes && (
                            <Controller
                              name="duration"
                              control={control}
                              render={({ field: { ref, ...field } }) => (
                                <Typography variant="subtitle2" sx={{ marginTop: 0 }}>
                                  {field.value ? formatDuration(field.value) : formatDuration(0)}
                                </Typography>
                              )}
                            />
                          )
                        )}
                      </FormControl>
                      {isPoll && (
                        <FormControl sx={{ marginBottom: 0 }}>
                          <FormLabel sx={{ margingLeft: 1, marginBottom: !isSavedMeeting ? "8px" : "0px" }}> 
                            # Expected Attendees
                          </FormLabel>
                          {!isSavedMeeting && !meeting.enable_public_attendee_list ? (
                            <Controller name="attendees" control={control} 
                              rules={{ required: true, validate: attendeeValidation }}
                              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                                return <>
                                  <CabTextInput
                                    {...field}
                                    inputRef={ref}
                                    size={"xsmall"}
                                    sx={{
                                      maxWidth: 160,
                                      // hide up down arrows
                                      // Chrome, Safari, Edge, Opera
                                      "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                      },
                                      // Firefox
                                      'input[type=number]': {
                                        MozAppearance: 'textfield',
                                      },
                                    }}
                                    inputProps={{
                                      min: meeting.leader_info?.length || 1,
                                      type: 'number',
                                      sx: { paddingTop: "6px", paddingBottom: "6px", paddingLeft: 1 }
                                    }}
                                  />
                                  {error && (
                                    <Box maxWidth={'160px'}>
                                      <Alert severity="warning" variant="outlined"
                                        sx={{fontSize: '12px', padding: '4px', marginTop: '4px', 
                                          "& .MuiAlert-icon": {
                                            fontSize: 20
                                          },
                                          "& .MuiAlert-message": {
                                            padding: 0
                                          }}}>
                                        {error?.type === 'required' ? 'Expected Attendees required' 
                                          : error?.type === 'number' ? 
                                            `You already have ${meetingParticipants
                                              .length} attendees listed for this poll`
                                            : error?.type === 'notZero' ? 
                                              '0 is not valid'
                                              : null}
                                      </Alert>
                                    </Box>
                                  )}
                                </>;
                              }}
                            />
                          ) : (
                            <CabTooltip
                              title={meeting.enable_public_attendee_list
                                ? 'This number is calculated from your attendee list'
                                : ''}
                              placement="bottom"
                            >
                              <Typography variant="subtitle2">
                                {meeting.num_expected_participants}
                              </Typography>
                            </CabTooltip>
                          )}
                        </FormControl>
                      )}
                    </Box>
                    {isSavedMeeting && (
                      <Box display="flex" width="100%" gap={1} sx={{marginTop: "12px !important"}}>
                        <CabButton
                          buttonType='tertiary'
                          size="small"
                          onClick={() => setOpenEditModal(true)}
                          icon={<CabIcon Icon={IoPencil} alt='Edit' />}
                          sx={{
                            color: colors.greenLight,
                            borderColor: colors.greenLight,
                            '&:hover': { borderColor: lighten(colors.greenPrimary, 0.3) }
                          }}
                        >
                          {'Edit Meeting Info'}
                        </CabButton>
                      </Box> 
                    )}            
                    {/* currently we need to check if selectedCalendar has changed from the undefined value */}
                    {isSavedMeeting && currentCal !== undefined && (
                      <>
                        <Divider />
                        <CalendarAndLocation
                          isOwner={isOwner}
                          meeting={meeting}
                          zoomSettings={zoomSettings}
                          user={user}
                          leaders={leaders}
                          leaderCalendarOptions={leaderCalendarOptions}
                          onAddZoom={onAddZoom}
                          calendars={calendars}
                          meetingRooms={meetingRooms}
                          presetLocations={presetLocations}
                          onOpenQuestionModal={() => setOpenQuestionModal(true)}
                          control={control as unknown as Control}
                          locations={locations}
                          locationsForm={locationsForm}
                          onSubmit={onSubmit}
                          noSelectedLeaders={selectedLeaderIds.length === 0}
                        />
                        <Divider />
                      </>
                    )}
                  
                    <MeetingParticipants
                      leaderInfo={meeting.leader_info || []}
                      additionalParticipants={meetingParticipants}
                      meetingLeaders={meetingLeaders}
                      isEditable={isOwner}
                      onRemoveLeader={onRemoveLeader}
                      handleDeleteAttendee={handleDeleteAttendee}
                      onClickParticipant={() => setEditParticipantsModalOpen(true)}
                    />
                    <CabList 
                      items={[
                        { 
                          key: 'Edit',
                          value: 'Edit', 
                          label: <Box display='flex' width='100%' justifyContent='center' alignItems='center'>
                            <CabIcon Icon={IoAddOutline} sx={{fontSize: '25px', marginRight: '4px'}}/>
                            Edit
                          </Box>, 
                          editable: false,
                          onClick: () => setEditParticipantsModalOpen(true),
                          disabled: !isOwner,
                        }
                      ]}
                    />
                  </Box>
                </Accordion>

                {isSavedMeeting && (
                  <Divider />
                )}

                <Accordion elevation={0} disableGutters expanded={expandedSection === 'times'}
                  sx={{
                    backgroundColor: 'transparent',
                    '&:before': {
                      display: 'none',
                    }
                  }}>
                  {isSavedMeeting && (
                    <AccordionSummary
                      expandIcon={<CabIcon Icon={IoChevronDown} />}
                      onClick={() => expandSection(expandedSection === 'times' ? null : 'times')}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ backgroundColor: colors.navyDark, padding: 0, minHeight: 32,
                        '& .MuiAccordionSummary-content': {
                          marginTop: 0,
                          marginBottom: 0,
                        }
                      }}
                    >
                      <Typography variant="h2" color={colors.white900} display='flex' gap={1}>
                        <CabIcon Icon={IoCalendarOutline} sx={{ fontSize: '20px' }} />
                        {isPoll ? 'Poll Times' : 'Meeting Times'}
                      </Typography>
                    </AccordionSummary>
                  )}
                  <Box display='flex' flexDirection='column' gap={1}>
                    {!isSavedMeeting && (
                      <>
                        <Typography variant='h2'>Set Availability</Typography>
                        <Typography variant="body2" color="#fff" marginTop="2px">
                          {dragTimeslotsText}
                        </Typography>
                      </>
                    )}
                    <Box sx={{marginBottom: 2}}>
                      <CabTooltip
                        title={'Enable this if you would like to specify exact time slots rather than have Cabinet'
                          + ' calculate all availability. This will lock slot length to your meeting duration.'}
                        placement="left"
                        wrapWithSpan
                      >
                        <Controller
                          name="selectExactTimes"
                          control={control}
                          render={({ field: { value, ref, onChange, ...field } }) => (
                            <CabControlLabel
                              control={(
                                <CabSwitch
                                  {...field}
                                  checked={value}
                                  onChange={!value && meetingSlots.length > 0 ?
                                    () => setOpenSlotChangeModal(true)
                                    : (e, checked) => (
                                      onChange(e, checked))
                                  }
                                  disabled={meetingForm.formState.isSubmitting
                                    || slotsAreRecalculating
                                    || disableSelectExactTimes}
                                />
                              )}
                              label="Select exact time blocks"
                              labelPlacement="end"
                            />
                          )} />
                      </CabTooltip>
                    </Box>
                  
                    {meetingSlots.length > 0 && (
                      <Typography variant='h2'>Selected Times</Typography>
                    )}
                    <TimeSlotChips
                      readonly={false}
                      selectedSlots={meetingSlots}
                      currentMeetingId={meeting.id}
                      handleDeleteSlots={onDeleteSlots}
                      timezone={calendarTimezone}
                      control={control}
                    />
                    {isReusable && (
                      <Box display='flex' justifyContent='space-between' 
                        alignItems='center' sx={{marginTop: '8px'}}>
                        <Typography variant='h2'>Recurring Range</Typography>
                        {meeting.recurring_times && !!isAnyRecurringDayEnabled && (
                          <CabButton
                            buttonType='tertiary'
                            size='small'
                            icon={<CabIcon Icon={IoPencilOutline} sx={{fontSize: '16px'}} />}
                            sx={{height: '24px'}}
                            onClick={() => setRecurringRangeModalOpen(true)}
                          >
                            Edit Range 
                          </CabButton>
                        )}
                      </Box>
                    )}
                    {isReusable && (
                      !recurringTimes || !isAnyRecurringDayEnabled ? (
                        <CabList 
                          items={[
                            { 
                              key: 'Add Recurring Range',
                              value: 'Add Recurring Range', 
                              label: <Box display='flex' width='100%' justifyContent='center' alignItems='center'>
                                <CabIcon Icon={IoAddOutline} sx={{fontSize: '25px', marginRight: '4px'}}/>
                                Add Recurring Range
                              </Box>, 
                              editable: false,
                              onClick: () => setRecurringRangeModalOpen(true)
                            }
                          ]}
                        />
                      ) : (
                        <Box>
                          <RecurringDayChips
                            recurringTimes={recurringTimes}
                            timezone={calendarTimezone}
                            onSlotClick={() => setRecurringRangeModalOpen(true)}
                          />
                        </Box>
                      )
                    )}
                  </Box>
                </Accordion>

                <Divider />

                {(features?.MEETING_FILES && hasMicrosoftGrant) && <>
                  <Accordion elevation={0} disableGutters expanded={expandedSection === 'files'}
                    sx={{
                      backgroundColor: 'transparent',
                      '&:before': {
                        display: 'none',
                      }
                    }}>
                    {isSavedMeeting && (
                      <AccordionSummary
                        expandIcon={<CabIcon Icon={IoChevronDown} />}
                        aria-controls="panel1a-content"
                        onClick={() => expandSection(expandedSection === 'files' ? null : 'files')}
                        id="panel1a-header"
                        sx={{ backgroundColor: colors.navyDark, padding: 0, minHeight: 32,
                          '& .MuiAccordionSummary-content': {
                            marginTop: 0,
                            marginBottom: 0,
                          }
                        }}
                      >
                        <Typography variant="h2" color={colors.white900} display='flex' gap={1}>
                          <CabIcon Icon={IoDocumentOutline} sx={{ fontSize: '20px' }} />
                          Files
                        </Typography>
                      </AccordionSummary>
                    )}
                    <Box>
                      <Controller
                        name="files"
                        control={control}
                        render={({field: {onChange, value, ...fields}}) => {
                          return <>
                            <Typography variant='h2'>Add File Attachments</Typography>
                            <Typography variant="body2" color="#fff" marginTop="2px">
                              {uploadFilesText}
                            </Typography>
                            <Button component="label" color="secondary" variant="contained" 
                              sx={{width: "100%", marginTop: 1}}
                            >
                              Add File
                              <input
                                {...fields}
                                hidden
                                type="file"
                                accept={ACCEPTED_MIME_EXTS.join(",")}
                                multiple
                                onChange={(e) => {
                                  const max_total_size = 30 * 1000 * 1000;
                                  const max_file_size = 3 * 1000 * 1000;
                                  const files = Array.from(e.target.files || []);
                                  let size = 0;
                                  const uploadFiles: File[] = [];
                                  let errorExists = false;
                                  for (let i = 0; i < files.length; i++) {
                                    const file = files[i];
                                    if (file.size + size < max_total_size && file.size < max_file_size) {
                                      uploadFiles.push(file);
                                      size += file.size;
                                    } else {
                                      errorExists = true;
                                      break;
                                    }
                                  }
                                  if (!errorExists) {
                                    onChange([...(value || []), ...uploadFiles]);
                                  }
                                  setMaxFilesExceeded(errorExists);
                                }}
                              />
                            </Button>
                            {maxFilesExceeded &&
                              <Alert severity="error" variant="outlined" sx={{marginTop: 1}}>
                                Each file must be less than 3MB and a total of 30MB can be uploaded
                              </Alert>
                            }
                            {((meeting.files && meeting.files.length > 0) || (value && value.length > 0)) &&
                              <Box sx={{marginTop: 1}}>
                                <Typography variant='h2'>
                                  Attached Files
                                </Typography>
                                {meeting.files?.map((file) => {
                                  return <CabToggleChip
                                    key={file.id}
                                    label={file.filename}
                                    sx={{marginTop: 0.5, width: "100%", justifyContent: "space-between"}}
                                    onDelete={() => deleteMeetingFile(file.id, meeting.id)}
                                  />;
                                })}
                                {/* This is here to show the file name while it's uploading */}
                                {value?.map((file) => {
                                  return <CabToggleChip
                                    key={file.name}
                                    label={file.name}
                                    sx={{marginTop: 0.5, width: "100%", justifyContent: "space-between"}}
                                    onDelete={() => onChange(value.filter(f => f !== file))}
                                  />;
                                })}
                              </Box>
                            }
                          </>;
                        }}
                      />
                    </Box>
                  </Accordion>
                  <div ref={filesScrollRef}>
                    <Divider />
                  </div>
                </>
                }

                {isSavedMeeting && (
                  <>
                    <Accordion elevation={0} disableGutters expanded={expandedSection === 'holds'}
                      sx={{
                        backgroundColor: 'transparent',
                        '&:before': {
                          display: 'none',
                        }
                      }}>
                      <AccordionSummary
                        expandIcon={<CabIcon Icon={IoChevronDown} />}
                        aria-controls="panel1a-content"
                        onClick={() => expandSection(expandedSection === 'holds' ? null : 'holds')}
                        id="panel1a-header"
                        sx={{ backgroundColor: colors.navyDark, padding: 0, minHeight: 32,
                          '& .MuiAccordionSummary-content': {
                            marginTop: 0,
                            marginBottom: 0,
                          }
                        }}
                      >
                        <Typography variant="h2" color={colors.white900} display='flex' gap={1}>
                          <CabIcon Icon={IoBookmarkOutline} sx={{ fontSize: '20px' }} />
                          Calendar Holds
                        </Typography>
                      </AccordionSummary>
                      <Typography variant='body2' color={colors.white600}>
                        Select the calendars you want holds to be created on
                      </Typography>
                      <ExecutiveHoldControl
                        control={control}
                        meeting={meeting}
                        isOwner={isOwner}
                        showHolds={showHolds}
                        silenceHolds={schedulingPrefs.user_prefs?.silence_executive_hold_notice || false}
                        openUpgradeModal={openUpgradeModal}
                        setExecutiveHoldAlertOpen={setExecutiveHoldAlertOpen}
                        meetingForm={meetingForm}
                        calendarOptions={holdCalendarOptions}
                        meetingErrors={meetingErrors}
                        onShowErrors={onShowErrors}
                      />
                    </Accordion>
                    <div ref={holdsScrollRef}>
                      <Divider />
                    </div>
                  </>
                )}

                {(isSavedMeeting) && (
                  <>
                    <Accordion elevation={0} disableGutters expanded={expandedSection === 'questions'}
                      sx={{
                        backgroundColor: 'transparent',
                        '&:before': {
                          display: 'none',
                        }
                      }}>
                      <AccordionSummary
                        expandIcon={<CabIcon Icon={IoChevronDown} />}
                        aria-controls="panel1a-content"
                        onClick={() => expandSection(expandedSection === 'questions' ? null : 'questions')}
                        id="panel1a-header"
                        sx={{ backgroundColor: colors.navyDark, padding: 0, minHeight: 32,
                          '& .MuiAccordionSummary-content': {
                            marginTop: 0,
                            marginBottom: 0,
                          }
                        }}
                      >
                        <Typography variant="h2" color={colors.white900} display='flex' gap={1}>
                          <CabIcon Icon={IoHelpCircleOutline} sx={{ fontSize: '20px' }} />
                          {isPoll ? 'Poll Questions' : 'Meeting Questions'}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0}}>
                        <Box marginTop={2}>
                          <QuestionChips
                            questions={meeting.questions || {}}
                            handleAddQuestion={addQuestionButtonClicked}
                            addButton
                            currentMeetingId={meeting.id}
                            readonly={true}
                            isPoll={isPoll ?? false}
                            meetingFields={{title: meeting.title || '', description: meeting.description, 
                              locations: meeting.locations}}
                            hasSingleSelectOption={user?.features.SINGLE_SELECT_QUESTIONS}
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <div ref={questionsScrollRef}>
                      <Divider />
                    </div>
                  </>
                )}
              </>
            )}
            {step === 1 && !isSavedMeeting && (
              <StyledPollPanelBox>
                <Box display='flex' flexDirection='column' gap={1} id='Meeting-settings-question-container'>
                  <Typography variant='h2'>
                    {isPoll ? 'Poll Questions' : 'Meeting Questions'}
                  </Typography>
                  <Typography variant="body2" color={colors.white700} fontWeight={500}>
                    {isPoll ? 'Add questions for your poll participants to answer.' 
                      : 'Add questions for your meeting respondents to answer.'}
                  </Typography>
                  <QuestionChips
                    questions={meeting.questions || {}}
                    handleAddQuestion={addQuestionButtonClicked}
                    addButton
                    currentMeetingId={meeting.id}
                    readonly={true}
                    isPoll={isPoll ?? false}
                    hasSingleSelectOption={user?.features.SINGLE_SELECT_QUESTIONS}
                  />
                </Box>
                <Box>
                  <Typography variant='h2'>Calendar Holds</Typography>
                  <Typography variant='body2' color={colors.white600}>
                    Select the calendars you want holds to be created on
                  </Typography>
                  <ExecutiveHoldControl
                    control={control}
                    meeting={meeting}
                    isOwner={true}
                    showHolds={showHolds}
                    silenceHolds={schedulingPrefs.user_prefs?.silence_executive_hold_notice || false}
                    openUpgradeModal={openUpgradeModal}
                    setExecutiveHoldAlertOpen={setExecutiveHoldAlertOpen}
                    meetingForm={meetingForm}
                    calendarOptions={holdCalendarOptions}
                    meetingErrors={meetingErrors}
                    onShowErrors={onShowErrors}
                  />
                </Box>
              </StyledPollPanelBox>
            )}
          </Stack>

          {/* TODO: Make each modal/dialog section its own component.
          Maybe multiple exports from separate file, e.g. MeetingModals? */}

          <DialogActionsStyled>
            {!isSavedMeeting ? (
              <Box display="flex" flexDirection='column' width="100%">
                <StyledLine/>
                <Box marginBottom={'4px'}>
                  <MobileStepper
                    steps={2}
                    position="static"
                    activeStep={step}
                    nextButton={null}
                    backButton={null}
                    sx={{ justifyContent: 'center', backgroundColor: 'transparent' }}
                  />
                </Box>
                <Box display="flex" width="100%">
                  {!isSavedMeeting && step === 0 && (
                    <NextButton control={control} onClick={() => setStep(1)} />
                  )}
                  {step === 1 && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <CabButton 
                        buttonType='primary' color="secondary" size="large" 
                        sx={{flex: 1, marginRight: 1, maxWidth: '45%'}}
                        onClick={() => setStep(0)}>
                        Previous
                      </CabButton>
                      <CabButton type="submit" buttonType='primary' size="large" 
                        id='MeetingSettings-save-meeting-btn'
                        disabled={meetingForm.formState.isSubmitting} 
                        sx={{flex: 1, marginLeft: 1, minWidth: '55%'}}
                      >
                        {meetingForm.formState.isSubmitting
                          ? <CabSpinner scale={1} sx={{ width: 32 }} /> 
                          : isPoll ? 'Save Poll' : 'Save Meeting'}
                      </CabButton>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection={"column"} width={"100%"}>
                <CabButton buttonType='tertiary' size="large" onClick={handleCancel}
                  sx={{ flex: 1, marginBottom: "8px" }} 
                >
                  Close
                </CabButton>
                <CabButton buttonType='primary' size="large" onClick={handleShare} 
                  id='MeetingSettings-share-meeting-btn' sx={{ flex: 1 }} 
                >
                  Share
                </CabButton>              
              </Box>
            )}
          </DialogActionsStyled>

          {loading && (
            <Box position="absolute" height="100%" width="100%" sx={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
              <CabSpinner scale={2} sx={{ marginTop: '50%' }} />
            </Box>
          )}
        </Box>
      </form>

      <ThemeProvider theme={theme}>
        <CabModal
          open={showNoSlotsWarning || showSlotDurationWarning}
          onClose={() => showNoWarnings()}
          title='Select Time Slots'
          isAlert
          closeOnBackdropClick
          actionButtons={
            <>
              <CabButton buttonType="tertiary" color='primary' onClick={() => showNoWarnings()}>
                {showNoSlotsWarning || showSlotDurationWarning ? 'OK' : 'Cancel'}
              </CabButton>
            </>
          }
          sx={{ zIndex: 1305 }}
        >
          {showNoSlotsWarning && (
            <DialogContentText>
              You must select at least one time slot.
            </DialogContentText>
          )}

          {showSlotDurationWarning && (
            !isPoll
              ? <DialogContentText>
                Some of your selected slots are shorter than the duration of your meeting,
                so they will not be shown to attendees.
              </DialogContentText>
              : <Box>
                <DialogContentText>
                  Some slots have a duration shorter than your meeting length
                </DialogContentText>
              </Box>
          )}
        </CabModal>

        <CabModal
          open={openSlotChangeModal}
          onClose={() => setOpenSlotChangeModal(false)}
          title='Slot Change Warning'
          isAlert
          closeOnBackdropClick
          text="Changing this setting may cause your selected slots to change."
          actionButtons={
            <>
              <CabButton buttonType="tertiary" color='primary' onClick={() => setOpenSlotChangeModal(false)}>
                {'Cancel'}
              </CabButton>
              <CabButton buttonType="primary" color='primary' 
                onClick={() => selectExactTimes()}>
                {'OK'}
              </CabButton>
            </>
          }
        />

        {executiveHoldAlertOpen != null && (
          <ExecutiveHoldAlert
            calId={executiveHoldAlertOpen}
            onClose={() => setExecutiveHoldAlertOpen(null)}
            executeAddHoldCalendar={executeAddHoldCalendar}
            updateExecHoldNoticePref={updateExecHoldNoticePref}
            silence_executive_hold_notice={schedulingPrefs?.user_prefs?.silence_executive_hold_notice || false}
          />
        )}

        <EditMeetingInfoModal
          open={openEditModal}
          onCancel={handleCancelEditModal}
          control={control}
          isFormValid={meetingForm.formState.isValid}
          isPoll={isPoll || false}
          onSubmit={formSubmit(handleSubmit)}
          showTokenInputs={showTokenInputs}
          meeting={meeting}
          templateVars={templateVars}
          templateAddQuestionButtonClicked={templateAddQuestionButtonClicked}
          descriptionDisabled={features?.DISABLE_EVENT_DESCRIPTION || false}
          // attendeeValidation={attendeeValidation}
        />

        {openQuestionModal && (
          <QuestionModal
            open={openQuestionModal}
            questions={meeting.questions || {}}
            forceRequired={forceRequired ?? undefined}
            onClose={() => setOpenQuestionModal(false)}
            currentMeetingId={meeting.id}
            isPoll={isPoll ?? false}
            hasSingleSelectOption={user?.features.SINGLE_SELECT_QUESTIONS}
          />
        )}

        <PollUpdateModal
          open={pollUpdateOpen}
          pollUpdateHandlerAccept={handlePollVotesAccept}
          pollUpdateHandlerReject={handlePollVotesReject}
          invalidMeetingSlots={invalidMeetingSlots}
          timezone={calendarTimezone}
        />

        {recurringRangeModalOpen && (
          <RecurringRangeModal
            open={recurringRangeModalOpen}
            timezone={calendarTimezone}
            onClose={() => setRecurringRangeModalOpen(false)}
            onSave={(newRecurringTimes) => saveRecurringAvailability(newRecurringTimes)}
            recurringTimes={recurringTimes || undefined}
            excludedMeetingSlots={meetingSlots.filter(slot => slot.is_exclude)}
            meetingId={meeting.id}
            onDeleteSlots={onDeleteSlots}
            onEditSlot={onEditSlot}
            onExcludedSlotsCreated={onExcludedSlotsCreated}
            onCalendarTimezoneSelected={onCalendarTimezoneSelected}
          />
        )}
        {editParticipantsModalOpen && (
          <EditParticipantsModal
            open={editParticipantsModalOpen}
            onClose={() => setEditParticipantsModalOpen(false)}
            meetingId={meeting.id}
          />
        )}
      </ThemeProvider>
    </>
  );
};

const NextButton = ({ control, onClick }: { control: Control<MeetingSettingFormInput>; onClick: () => void }) => {
  const meetingName = useWatch({ control: control, name: 'meetingName' });

  return (
    <CabButton
      onClick={onClick}
      size="large"
      buttonType='primary' 
      id="MeetingSettings-next-btn"
      // disabled={!meetingForm.formState.isValid}
      disabled={!meetingName}
      sx={{ flex: 1 }}
    >
      Next
    </CabButton>
  );
};

const DialogActionsStyled = styled(
  DialogActions, { label: "DialogActionsStyled" }
)<CabComponentProps<DialogActionsProps>>(() => ({
  padding: 1,
  paddingTop: 8,
  marginBottom: 4,
  paddingBottom: 8,
  position: 'sticky',
  backgroundColor: colors.navyDark,
  bottom: 0,
}));

const StyledPollPanelBox = styled(Box, { label: 'StyledPollPanelBox' })(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));

const StyledLine = styled('div', { label: 'StyledLine' })(() => ({
  marginLeft: -10, 
  marginRight: -10, 
  marginBottom: 2,
  borderBottom: '1px solid', 
  borderColor: colors.white200
}));

type MeetingSettingsViewProps = Omit<MeetingSettingsProps, 'meeting'|'meetingType'>
& { meeting: MeetingSettingsProps['meeting'] | null };

const MeetingSettingsView = (props: MeetingSettingsViewProps): ReactElement | null => {
  const [meetingType, setMeetingType] = useState<keyof typeof MEETING_TYPE>('ONE_OFF');

  const isSavedMeeting = props.meeting && props.meeting.id !== -1;

  useEffect(() => {
    if (props.open) {
      setMeetingType(props.meeting?.is_poll ? "POLL" : props.meeting?.is_reusable ? "REUSABLE" : "ONE_OFF");
    } else {
      setMeetingType("ONE_OFF");
    }
  }, [props.meeting?.is_poll, props.meeting?.is_reusable, props.open]);

  return <ThemeProvider theme={darkTheme}>
    <CabPanel
      open={props.open}
      onClose={props.onCancel}
      anchor="right"
      title={isSavedMeeting ? (
        props.meeting?.is_poll ? 'Meeting Poll' : props.meeting?.is_reusable ? 'Reusable Meeting' : 'One-off Meeting'
      ) : (
        <CabDropdown<keyof typeof MEETING_TYPE>
          onChange={(e) => setMeetingType(e.target.value as keyof typeof MEETING_TYPE)}
          value={meetingType}
          size='small'
          options={Object.keys(MEETING_TYPE).map((interval) => ({
            value: interval as keyof typeof MEETING_TYPE,
            label: <Box display="flex" flexWrap={"nowrap"} alignItems={"center"}>
              {MEETING_TYPE[interval as keyof typeof MEETING_TYPE]}
              {(interval === "POLL" && !props.user?.features?.MEETING_POLLS) && <CabIcon 
                sx={{marginLeft: 1}}
                Icon={IoLockClosed}
              />}
            </Box>,

            disabled: (interval === "POLL" && !props.user?.features?.MEETING_POLLS)
          }))}
          sx={{ width: '100%', fontSize: '16px', fontWeight: 700, height: '26px', marginBottom: 1, marginTop: 1,
            '& .MuiInputBase-input': {
              lineHeight: '20px',
            }
          }}
        />
      )}
      closeIcon
    >
      {props.meeting 
        ? <MeetingSettings {...props} meeting={props.meeting} meetingType={meetingType} />
        : null}
    </CabPanel>
  </ThemeProvider>;
};


export default MeetingSettingsView;
