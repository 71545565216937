import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { 
  Accordion, AccordionDetails, AccordionSummary, Box, Divider, FormControlLabel, MobileStepper, Radio, RadioGroup, 
  Typography 
} from "@mui/material";
import { DateRangePicker, SingleInputDateTimeRangeField } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import colors from "../../../colors";
import { 
  AvailabilitySlotTimes,
  CopyToDays,
  MeetingSlot, RecurringTimeFormData, RecurringTimeOptionLimit, RecurringTimes, RecurringTimesLimit 
} from "../../../store";
import { getDefaultRecurringTimesTemplate } from "../../../store/templates";
import { 
  allTimeZones,
  convertRecurringTimesFormDataToRecurringTimes, convertRecurringTimesToRecurringTimesFormData, 
  ExcludedSlotInfo, TimeZone 
} from "../../../utils/scheduleUtils";
import RecurringAvailabilityDay from "./RecurringAvailabilityDay";
import SingleDateExcludedTimes from "./SingleDateExcludedTimes";
import CopyToPopover from "./CopyToPopover";
import { IoArrowForwardOutline, IoChevronDown, IoCalendarClearOutline } from "react-icons/io5";
import { CabTimezoneInput } from "@CabComponents/CabTimezoneInput";


export interface RecurringRangeModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (recurringTimes: RecurringTimes) => void;
  timezone: TimeZone | undefined;
  recurringTimes?: RecurringTimes;
  excludedMeetingSlots: MeetingSlot[];
  meetingId: number;
  onExcludedSlotsCreated: (slots: ExcludedSlotInfo[]) => void;
  onEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  onDeleteSlots: (slots: MeetingSlot[]) => void;
  onCalendarTimezoneSelected?: (timezone: TimeZone) => void;
}

const RecurringRangeModal = ({
  open, onClose, onSave, timezone, recurringTimes, excludedMeetingSlots, meetingId, onDeleteSlots, onEditSlot, 
  onExcludedSlotsCreated, onCalendarTimezoneSelected
}: RecurringRangeModalProps): ReactElement => {
  const initRecurringTimes = recurringTimes || getDefaultRecurringTimesTemplate();
  const [step, setStep] = useState(0);
  const [days, setDays] = useState(initRecurringTimes.options.limit.num_days || 1);
  const [excludedSlots, setExcludedSlots] = useState<MeetingSlot[]>(excludedMeetingSlots);
  const [range, setRange] = useState<[DateTime | null, DateTime | null]>([
    DateTime.fromISO(initRecurringTimes.options.limit.date_range.start), 
    DateTime.fromISO(initRecurringTimes.options.limit.date_range.end) 
  ]);
  const [radioValue, setRadioValue] = useState(
    initRecurringTimes.options.limit.selected !== null ? initRecurringTimes.options.limit.selected : 'any'
  );
  const [limit, setLimit] = useState<RecurringTimesLimit>(initRecurringTimes.options.limit);
  const [showCopyPopover, setShowCopyPopover] = useState(false);
  const [copyTimes, setCopyTimes] = useState<AvailabilitySlotTimes[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const availableTimesData = convertRecurringTimesToRecurringTimesFormData(initRecurringTimes);
  const maxDays = 365;

  useEffect(() => {
    setExcludedSlots(excludedMeetingSlots);
  }, [excludedMeetingSlots]);

  useEffect(() => {
    setLimit(
      {
        selected: radioValue === RecurringTimeOptionLimit.NUM_DAYS ? RecurringTimeOptionLimit.NUM_DAYS : 
          radioValue === RecurringTimeOptionLimit.DATE_RANGE ? RecurringTimeOptionLimit.DATE_RANGE : null,
        num_days: days,
        date_range: {start: range[0]?.toISODate() || '', end: range[1]?.toISODate()  || ''}
      }
    );
  }, [days, radioValue, range]);

  const {
    control: availabilityControl, getValues: getAvailabilityData, setValue
  } = useForm<RecurringTimeFormData>({
    defaultValues: availableTimesData
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleCopyTimesTo = (times: AvailabilitySlotTimes[], curDays: CopyToDays) => {
    if (curDays.mon) {
      setValue('mon.times', times);
    }
    if (curDays.tue) {
      setValue('tue.times', times);
    }
    if (curDays.wed) {
      setValue('wed.times', times);
    }
    if (curDays.thu) {
      setValue('thu.times', times);
    }
    if (curDays.fri) {
      setValue('fri.times', times);
    }
    if (curDays.sat) {
      setValue('sat.times', times);
    }
    if (curDays.sun) {
      setValue('sun.times', times);
    }
    handleClosePopover();
  };

  const handleClickCopy = (times: AvailabilitySlotTimes[], e: React.MouseEvent<HTMLButtonElement>) => {
    setCopyTimes(times);
    setAnchorEl(e.currentTarget);
    setShowCopyPopover(true);
  };

  const handleClosePopover = () => {
    setShowCopyPopover(false);
    setCopyTimes([]);
    setAnchorEl(null);
  };

  const submitData = () => {
    const newRecurringTimes = convertRecurringTimesFormDataToRecurringTimes(getAvailabilityData(), {limit: limit});
    const newExcludedSlots = excludedSlots.filter(slot => slot.id === -1).map(slot => (
      {start: new Date(slot.start_date), end: new Date(slot.end_date)}
    ));
    onExcludedSlotsCreated(newExcludedSlots);
    onSave(newRecurringTimes);
    onClose();
  };

  return (
    <CabModal
      open={open}
      onClose={onClose}
      title={step === 0 ? 'Select Times' : 'Choose End of Availability'}
      closeIcon
      sx={{
        '& .MuiDialog-paper': {
          width: 690,
          maxWidth: 690,
          height: '80vh',
          maxHeight: 720
        },
      }}
      actionButtons={
        <Box display='flex' justifyContent='space-between' width='100%'>
          <Box>
            <MobileStepper
              steps={2}
              position="static"
              activeStep={step}
              nextButton={null}
              backButton={null}
              sx={{ justifyContent: 'center', backgroundColor: 'transparent' }}
            />
          </Box>
          <Box>
            {step === 0 ? (
              <CabButton buttonType="primary" onClick={() => setStep(1)}
                endIcon={<CabIcon Icon={IoArrowForwardOutline} />}>
                Continue to Options
              </CabButton>
            ) : (
              <>
                <CabButton buttonType="tertiary" sx={{ marginRight: 1 }} onClick={() => setStep(step - 1)}>
                  Back
                </CabButton>
                <CabButton buttonType="primary" onClick={submitData}>
                  Save Recurring Range
                </CabButton>
              </>
            )}
          </Box>
        </Box>
      }
      stickyBottomContent={ step === 0 ? (
        <>
          <Divider sx={{width: '100%'}} />
          <Accordion elevation={0} square disableGutters
            sx={{
              paddingLeft: 1,
              paddingRight: 1,
              backgroundColor: 'transparent',
              '&:before': {
                display: 'none',
              }
            }}>
            <AccordionSummary
              expandIcon={<CabIcon Icon={IoChevronDown} sx={{color: colors.black800}} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle2">Excluded Times</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{paddingTop: 0, maxHeight: '320px', overflow: 'auto'}}>
              <Box display='flex' flexDirection='column' gap={1}>
                <Typography variant="body2" lineHeight={1.25}>
                  Add time exclusions for one-off overrides, upcoming vacations, etc<br /><br />

                  Note: As long as "Prevent conflicts" is turned on for this meeting, 
                  any calendar events marked as Busy will be excluded automatically.
                </Typography>
                <SingleDateExcludedTimes
                  excludedMeetingSlots={excludedSlots}
                  setExcludedSlots={setExcludedSlots}
                  timezone={timezone}
                  meetingId={meetingId}
                  onDelete={onDeleteSlots}
                  onEditSlot={onEditSlot}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          <Divider sx={{width: '100%'}} />
        </>
      ) : (
        <></>
      )
      }
    >
      {step === 0 ? (
        <Box display='flex' flexDirection='column' gap={2}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant="subtitle2">Set recurring availability...</Typography>
            {onCalendarTimezoneSelected ? (
              <CabTimezoneInput
                size="small"
                label=""
                onChange={tzName => {
                  const tz = allTimeZones.find(zone => zone.name === tzName);
                  if (tz) {
                    onCalendarTimezoneSelected(tz);
                  }
                }}
                value={timezone?.name || ''}
                sx={{width: 220}}
              />
            ) : (
              <Typography variant="body2" fontWeight={500}>
                {timezone ? `All times in ${timezone.abbreviation}` : ''}
              </Typography>
            )}
          </Box>
          <Box display='flex' flexDirection='column' gap={'4px'}>
            <RecurringAvailabilityDay
              name={"mon"}
              control={availabilityControl}
              onCopy={handleClickCopy}
            />
            <Divider sx={{width: '100%', marginBottom: 1}} />
            <RecurringAvailabilityDay
              name={"tue"}
              control={availabilityControl}
              onCopy={handleClickCopy}
            />
            <Divider sx={{width: '100%', marginBottom: 1}} />
            <RecurringAvailabilityDay
              name={"wed"}
              control={availabilityControl}
              onCopy={handleClickCopy}
            />
            <Divider sx={{width: '100%', marginBottom: 1}} />
            <RecurringAvailabilityDay
              name={"thu"}
              control={availabilityControl}
              onCopy={handleClickCopy}
            />
            <Divider sx={{width: '100%', marginBottom: 1}} />
            <RecurringAvailabilityDay
              name={"fri"}
              control={availabilityControl}
              onCopy={handleClickCopy}
            />
            <Divider sx={{width: '100%', marginBottom: 1}} />
            <RecurringAvailabilityDay
              name={"sat"}
              control={availabilityControl}
              onCopy={handleClickCopy}
            />
            <Divider sx={{width: '100%', marginBottom: 1}} />
            <RecurringAvailabilityDay
              name={"sun"}
              control={availabilityControl}
              onCopy={handleClickCopy}
            />
          </Box>
        </Box>
      ) : (
        <Box display='flex' flexDirection='column' gap={2}>
          <Typography variant="subtitle2">Participants can schedule...</Typography>
          <RadioGroup
            value={radioValue}
            onChange={handleChange}
          >
            <FormControlLabel
              value='num_days'
              control={<Radio />}
              label={<Box display='flex' alignItems='center'>
                <CabTextInput
                  type='number'
                  value={Number(days).toString()}
                  InputProps={{
                    sx: {
                      "& input": {
                        textAlign: "center"
                      }
                    }
                  }}
                  onChange={(e) => {
                    let value = Number(e.target.value);
                    if (value > maxDays) value = maxDays;
                    setDays(value);
                  }}
                  size='small'
                  disabled={radioValue !== 'num_days'}
                  maxLength={3}
                  sx={{width: 90, marginRight: 1, textAlign: 'center'}}
                />
                <Typography variant="button"> days into the future </Typography>
              </Box>}
              labelPlacement='end'
            />
            <FormControlLabel
              value='date_range'
              control={<Radio />}
              label={<Typography variant="button"> 
                Within a date range
              </Typography>}
              labelPlacement='end'
            />
            {radioValue === 'date_range' && (
              <Box marginLeft={4}>
                <DateRangePicker
                  format={'dd  MMM yyyy'}
                  value={range}
                  onChange={(v) => setRange(v)}
                  slots={{ field: SingleInputDateTimeRangeField }}
                  slotProps={{ textField: { InputProps: { 
                    endAdornment: <CabIcon Icon={IoCalendarClearOutline} /> 
                  }}
                  }}
                  calendars={1}
                  sx={{ width: '400px',
                    '& .MuiOutlinedInput-root': {
                      height: 40,
                      '&.Mui-focused fieldset': {
                        borderColor: colors.black800,
                        borderWidth: 1
                      },
                    },
                  }}
                />
              </Box>
            )}
            <FormControlLabel
              value='any'
              control={<Radio />}
              label={<Typography variant="button"> 
                Any available time in the future
              </Typography>}
              labelPlacement='end'
            />
          </RadioGroup>
        </Box>
      )}
      <CopyToPopover
        open={showCopyPopover}
        onClosePopover={handleClosePopover}
        popoverAnchorEl={anchorEl}
        times={copyTimes}
        onCopyTimesTo={handleCopyTimesTo}
      />
    </CabModal>
  );
};

export default RecurringRangeModal;