import { CabList } from "@CabComponents/CabList";
import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ReactElement } from "react";
import colors from "../../../colors";
import { RecurringTimeFormData, RecurringTimes } from "../../../store";
import { convertRecurringTimesToRecurringTimesFormData, TimeZone } from "../../../utils/scheduleUtils";


export interface Props {
  recurringTimes: RecurringTimes;
  onSlotClick: () => void;
  timezone: TimeZone | undefined;
}

const RecurringDayChips = ({
  recurringTimes, onSlotClick, timezone
}: Props): ReactElement => {
  const recurringTimeData = convertRecurringTimesToRecurringTimesFormData(recurringTimes);

  const toFullDayName = (day: string) => {
    switch (day) {
      case 'mon':
        return 'Monday';
      case 'tue':
        return 'Tuesday';
      case 'wed':
        return 'Wednesday';
      case 'thu':
        return 'Thursday';
      case 'fri':
        return 'Friday';
      case 'sat':
        return 'Saturday';
      case 'sun':
        return 'Sunday';
    }
    return '';
  };

  return (
    <>
      {(Object.keys(recurringTimeData) as Array<keyof (RecurringTimeFormData)>).map((day) => (
        <div key={day}>
          {recurringTimeData[day].enabled && (
            <>
              <Typography variant="h6" sx={{fontSize: "0.875em", marginTop: 1}}>{toFullDayName(day)}</Typography>
              <CabList
                items={recurringTimeData[day].times.map((slot) => {
                  return {
                    value: `${day} ${slot.startTime}`,
                    label: (
                      <Box display="flex" gap={1}>
                        <span>{DateTime.fromISO(slot.startTime).toFormat('h:mma').toLowerCase()}</span>
                        <span>-</span>
                        <span>{DateTime.fromISO(slot.endTime).toFormat('h:mma').toLowerCase()}</span>
                      </Box>
                    ),
                    icon: <span>{timezone?.abbreviation || ''}</span>,
                    editable: true,
                    onClick: onSlotClick,
                    customEditIcon: <Typography sx={{color: colors.white600}}>Edit</Typography>
                  };
                })}
              />
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default RecurringDayChips;