import { CabCheckbox } from "@CabComponents/CabCheckbox";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabTimePicker } from "@CabComponents/CabTimePicker";
import { Box, Stack, Typography } from "@mui/material";
import { upperFirst } from "lodash-es";
import { DateTime } from "luxon";
import { ReactElement, useEffect } from "react";
import { 
  Control, Controller, useFieldArray, UseFieldArrayAppend, UseFieldArrayRemove, UseFieldArrayUpdate, useWatch
} from "react-hook-form";
import colors from "../../../colors";
import { AbbreviatedDays, AvailabilitySlot, AvailabilitySlotTimes, RecurringTimeFormData } from "../../../store";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { IoTrashOutline, IoAddOutline, IoCopyOutline } from "react-icons/io5";

export interface RecurringAvailabilityDayProps {
  name: AbbreviatedDays;
  control: Control<RecurringTimeFormData>;
  onCopy: (times: AvailabilitySlotTimes[], e: React.MouseEvent<HTMLButtonElement>) => void; 
  onDelete?: () => void;
}

const RecurringAvailabilityDay = ({
  name, control, onCopy, onDelete
}: RecurringAvailabilityDayProps): ReactElement => {
  const defaultAvailability: AvailabilitySlot["times"][number] = { startTime: '09:00', endTime: '15:00' };
  const { fields: dayFields, append, remove, update } = useFieldArray({ control, name: `${name}.times` });


  return (
    <Box>
      <Stack gap={1}>
        <Box width="100%" display="flex" flexDirection="row" gap={2} alignItems="start">
          <Box display='flex' gap={1} alignItems='center' width={'70px'} paddingTop="6px">
            <Controller
              control={control}
              name={`${name}.enabled`}
              render={({field: {value, onChange, ...props}}) => <CabCheckbox
                sx={{
                  '&.Mui-checked': {
                    '& .MuiSvgIcon-root': {
                      color: colors.greenPrimary,
                    }
                  }
                }}
                checked={value}
                onChange={() => onChange(!value)}
              />
              }
            />
            <Typography variant="button">{upperFirst(name)}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            {dayFields.map((dayField, index) => {
              return <DayField
                key={JSON.stringify(dayField)}
                name={name}
                index={index}
                control={control}
                defaultAvailability={defaultAvailability}
                append={append}
                remove={remove}
                onDelete={onDelete}
                update={update}
                dayFieldsLength={dayFields.length}
                startTime={dayField.startTime}
                endTime={dayField.endTime}
                onCopy={onCopy}
              />;
            })}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

type DayFieldProps = {
  name: AbbreviatedDays;
  index: number;
  control: Control<RecurringTimeFormData>;
  defaultAvailability: AvailabilitySlot["times"][number];
  append: UseFieldArrayAppend<RecurringTimeFormData, `${AbbreviatedDays}.times`>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<RecurringTimeFormData, `${AbbreviatedDays}.times`>;
  onCopy: (times: AvailabilitySlotTimes[], e: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete?: () => void;
  dayFieldsLength: number;
  startTime?: string;
  endTime?: string;
};

const DayField = ({
  name, index, control, defaultAvailability, append, remove, onDelete, dayFieldsLength, startTime, endTime, update,
  onCopy
}: DayFieldProps) => {

  const isDayAvailable = useWatch({control, name: `${name}.enabled`});
  const maxTimeStr = useWatch({control, name: `${name}.times.${index}.endTime`});
  const minTimeStr = useWatch({control, name: `${name}.times.${index}.startTime`});
  const times = useWatch({control, name: `${name}.times`});

  const getAppendTime = () => {
    const newStart = times[times.length - 1].endTime;
    if (newStart < '23:00') {
      const newEnd = DateTime.fromISO(newStart).plus({hours: 1}).toFormat('HH:mm');
      return {
        startTime: newStart, endTime: newEnd
      };
    } else return {startTime: newStart, endTime: newStart};
  };

  useEffect(() => {
    if (minTimeStr > maxTimeStr) {
      update(index, {startTime: minTimeStr, endTime: minTimeStr});
    }
  }, [index, maxTimeStr, minTimeStr, update]);
  
  return <Box 
    display="grid"
    gridTemplateColumns="190px 10px 190px 50px 30px"
    gridAutoRows="1"
    marginBottom={1}
    alignItems="center"
    gap={1}
  >
    <Box gridColumn={0 / 5}  gridRow={1} width='100%'>
      <Controller
        name={`${name}.times.${index}.startTime` as `mon.times.0.startTime`}
        defaultValue={startTime || defaultAvailability.startTime}
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <>
            <CabTimePicker 
              {...field}
              fullWidth
              pickerTime={startTime || defaultAvailability.startTime}
              disabled={!isDayAvailable}
            />
          </>
        )}
      />
    </Box>
    <Box gridColumn={1 / 5} gridRow={1}><Typography variant="h5"> - </Typography></Box>
    <Box gridColumn={2 / 5} gridRow={1} width='100%'>
      <Controller
        name={`${name}.times.${index}.endTime` as `mon.times.0.endTime` }
        defaultValue={endTime || defaultAvailability.endTime}
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <>
            <CabTimePicker {...field} pickerTime={endTime || defaultAvailability.endTime} 
              disabled={!isDayAvailable} fullWidth minTime={minTimeStr} />
          </>
        )}
      />
    </Box>
    <Box gridColumn={3 / 5} gridRow={1}>
      {dayFieldsLength > 1 && isDayAvailable && (
        <CabIcon
          Icon={IoTrashOutline}
          onClick={() => {
            remove(index);
            if (onDelete) onDelete();
          }}
          sx={{ color: colors.black900 }}
        />
      )}
    </Box>
    <Box gridColumn={4 / 5} gridRow={1} sx={{marginLeft: 4}}>
      {(isDayAvailable && index === 0) && (
        <Box display='flex' gap={1}>
          <CabIcon
            Icon={IoAddOutline}
            onClick={() => append(getAppendTime())}
            sx={{ color: colors.black900 }}
          />
          <CabTooltip placement='top' wrapWithSpan title={'Copy times to another day'}>
            <CabIcon
              Icon={IoCopyOutline}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => onCopy(times, e)}
              sx={{ color: colors.black900 }}
            />
          </CabTooltip>
        </Box>
      )}
    </Box>
  </Box>;
};

export default RecurringAvailabilityDay;