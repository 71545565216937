import { Box, DialogContentText } from "@mui/material";
import { CabButton, CabModal } from '@CabComponents';
import { CabTextTokenDisplay } from "@CabComponents/CabTextTokenDisplay";
import { Meeting } from "../../../store";
import { getTemplateVars } from '../../../utils/scheduleUtils';


export type ReusableMeetingModalProps = {
  open: boolean;
  meeting: Meeting;
  onClose: () => void;
  onConvertToOneOff: () => void;
  onEditReusable: () => void;
};

const ReusableMeetingModal = ({
  open, onClose, meeting, onConvertToOneOff, onEditReusable
}: ReusableMeetingModalProps): React.ReactElement | null => {
  if (!meeting) return null;

  return (
    <CabModal
      open={open}
      onClose={onClose}
      title="Booked from Reusable Meeting"
      text="This meeting was generated from the following Reusable Meeting:"
      actionButtons={
        <Box display="flex" width="100%" justifyContent="space-between">
          <CabButton
            buttonType="tertiary"
            onClick={() => onClose()}
          >
            Cancel
          </CabButton>
          <Box display="flex" flexDirection="row" gap={1}>
            <CabButton
              onClick={onConvertToOneOff}
              sx={{ minWidth: 175 }}
            >
              Convert to One-Off
            </CabButton>
            <CabButton
              onClick={onEditReusable}
              sx={{ minWidth: 195 }}
            >
              Go to Reusable Meeting
            </CabButton>
          </Box>
        </Box>
      }
      sx={{ ".MuiPaper-root": { width: 800 } }}
    >
      <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
        <Box>
          <CabTextTokenDisplay templateVars={getTemplateVars(meeting.questions)}>
            {meeting.title}
          </CabTextTokenDisplay>
        </Box>
        <DialogContentText>
          If you want to customize this particular event (times offered, event title, etc.) you can convert this to
          a One-Off Meeting. If you want to let someone re-book from the same times, you can just go to the original
          Reusable Meeting.
        </DialogContentText>
      </Box>
    </CabModal>
  );
};

export default ReusableMeetingModal;
