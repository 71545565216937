import { CabButton } from "@CabComponents/CabButton";
import { CabControlLabel } from "@CabComponents/CabControlLabel";
import { CabModal } from "@CabComponents/CabModal";
import { CabSwitch } from "@CabComponents/CabSwitch";
import Typography from "@mui/material/Typography";

export type ExecutiveHoldAlertProps = {
  calId: number;
  onClose: () => void;
  executeAddHoldCalendar: (cal: number) => void
  updateExecHoldNoticePref: (value: boolean) => void;
  silence_executive_hold_notice: boolean;
};

export const ExecutiveHoldAlert = (
  { 
    calId, silence_executive_hold_notice,
    onClose, executeAddHoldCalendar, updateExecHoldNoticePref
  }: ExecutiveHoldAlertProps
): React.ReactElement => {
  return <CabModal
    open={calId > 0}
    onClose={onClose}
    actionButtons={<>
      <CabButton buttonType="tertiary" onClick={() => onClose()}>Cancel</CabButton>
      <CabButton onClick={() => executeAddHoldCalendar(calId)}>Confirm</CabButton>
    </>}>
    <Typography>
      This will add events to the selected executive calendars in order to hold the times selected for this
      meeting.
    </Typography>
    <Typography sx={{ marginTop: 1 }}>
      When your meeting is Scheduled or Cancelled, these holds will automatically be removed.
    </Typography>

    <CabControlLabel
      label="Don't Remind Me Again"
      sx={{ marginTop: 2, "& .MuiTypography-root": { fontWeight: 600, fontSize: "12px" } }}
      labelPlacement="end"
      control={<CabSwitch
        checked={silence_executive_hold_notice}
        onChange={(e, checked) => updateExecHoldNoticePref(checked)}
      />}
    />
  </CabModal>;
};

export default ExecutiveHoldAlert;