import { ReactElement, useEffect, useMemo, useState } from "react";
import { AvailabilitySlotTimes, CopyToDays } from "../../../store";
import { Box, FormControl, FormGroup, Popover, Typography } from "@mui/material";
import { CabControlLabel } from "@CabComponents/CabControlLabel";
import { CabCheckbox } from "@CabComponents/CabCheckbox";
import colors from "../../../colors";
import { CabButton } from "@CabComponents/CabButton";


export interface Props {
  open: boolean;
  onClosePopover: () => void;
  popoverAnchorEl: HTMLButtonElement | null;
  times: AvailabilitySlotTimes[];
  onCopyTimesTo: (times: AvailabilitySlotTimes[], days: CopyToDays) => void;
}

export const CopyToPopover = ({
  open, onClosePopover, popoverAnchorEl, times, onCopyTimesTo
}: Props): ReactElement => {

  const allFalse: CopyToDays = useMemo(() => ({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false
  }), []);

  const [copyDays, setCopyDays] = useState<CopyToDays>(allFalse);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCopyDays({
      ...copyDays,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    setCopyDays(allFalse);
  }, [open, allFalse]);

  const { mon, tue, wed, thu, fri, sat, sun } = copyDays;

  return (
    <Popover
      open={open}
      onClose={onClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      anchorEl={popoverAnchorEl as Element}
    >
      <Box display='flex' flexDirection='column' padding={1} gap={1}>
        <FormControl>
          <Typography variant='button' color={colors.black500}>Select days to copy times to:</Typography>
          <FormGroup>
            <CabControlLabel
              sx={{marginTop: 1}}
              label="Monday"
              labelPlacement="end"
              control={<CabCheckbox
                checked={mon}
                name={'mon'}
                onChange={handleChange}
              />}
            />
            <CabControlLabel
              sx={{marginTop: 1}}
              label="Tuesday"
              labelPlacement="end"
              control={<CabCheckbox
                checked={tue}
                name={'tue'}
                onChange={handleChange}
              />}
            />
            <CabControlLabel
              sx={{marginTop: 1}}
              label="Wednesday"
              labelPlacement="end"
              control={<CabCheckbox
                checked={wed}
                name={'wed'}
                onChange={handleChange}
              />}
            />
            <CabControlLabel
              sx={{marginTop: 1}}
              label="Thursday"
              labelPlacement="end"
              control={<CabCheckbox
                checked={thu}
                name={'thu'}
                onChange={handleChange}
              />}
            />
            <CabControlLabel
              sx={{marginTop: 1}}
              label="Friday"
              labelPlacement="end"
              control={<CabCheckbox
                checked={fri}
                name={'fri'}
                onChange={handleChange}
              />}
            />
            <CabControlLabel
              sx={{marginTop: 1}}
              label="Saturday"
              labelPlacement="end"
              control={<CabCheckbox
                checked={sat}
                name={'sat'}
                onChange={handleChange}
              />}
            />
            <CabControlLabel
              sx={{marginTop: 1}}
              label="Sunday"
              labelPlacement="end"
              control={<CabCheckbox
                checked={sun}
                name={'sun'}
                onChange={handleChange}
              />}
            />
          </FormGroup>
        </FormControl>
        <CabButton
          disabled={copyDays === allFalse}
          onClick={() => onCopyTimesTo(times, copyDays)}
        >
          Copy Times
        </CabButton>
      </Box>
    </Popover>
  );
};

export default CopyToPopover;