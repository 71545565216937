import { CabIcon, CabList, CabButton } from '@CabComponents';
import { Box, styled, ThemeProvider, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { IoAdd } from 'react-icons/io5';
import { MeetingQuestion } from "../../../store";
import colors from "../../../colors";
import QuestionModal, { templateFields } from './QuestionModal';
import theme from '../../../theme';


export interface QuestionChipsProps {
  questions: {[id: number]: MeetingQuestion};
  currentMeetingId: number | undefined;
  handleAddQuestion: () => void;
  readonly: boolean;
  addButton: boolean;
  isPoll: boolean;
  meetingFields?: templateFields;
  hasSingleSelectOption?: boolean;
}

const StyledBox = styled(Box, { label: 'StyledBox' })(() => ({
  border: '1px solid',
  borderColor: colors.white200,
  borderRadius: 8,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  paddingTop: 20,
  paddingBottom: 20
}));

const StyledQuestionContainer = styled(Box, { label: 'StyledQuestionContainer' })(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
}));

const QuestionChips = ({ questions, readonly, currentMeetingId, handleAddQuestion, isPoll, 
  meetingFields, hasSingleSelectOption
} : QuestionChipsProps): ReactElement => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editQuestion, setEditQuestion] = useState<MeetingQuestion['id']>();
  const questionsArray = Object.values(questions);

  const handleEdit = (question: MeetingQuestion['id']) => {
    setEditQuestion(question);
    setOpenEditModal(true);
  };

  return (
    <>
      {questionsArray.length === 0 ? (
        <StyledBox>
          <Typography variant="body2" color={colors.white700}>
            No questions have been added yet
          </Typography>
          <CabButton 
            buttonType='primary' color="secondary" size="large"
            onClick={handleAddQuestion}
            icon={<CabIcon Icon={IoAdd} alt='Add'/>}
          >
            Add Question
          </CabButton>
        </StyledBox>
      ) : (
        <StyledQuestionContainer>
          <CabList
            items={questionsArray.map((question, idx) => {
              const title = question.title || '';
              const questionString = (idx + 1) + '. ' + title;
              const requiredText = question.required ? 'Required' : 'Not Required';

              return {
                value: questionString,
                label: (
                  <Box display='flex' flexDirection='column'>
                    <Typography variant='body2' color={colors.white900} fontWeight={500}>
                      {questionString}
                    </Typography>
                    <Typography variant='body2' color={colors.white700} fontWeight={500}>
                      {requiredText}
                    </Typography>
                  </Box>
                ),
                editable: !readonly,
                onClick: () => handleEdit(question.id)
              };
            })}
          />
          <CabButton 
            buttonType='primary' color="secondary" size="large"
            onClick={handleAddQuestion}
            icon={<CabIcon Icon={IoAdd} alt='Add'/>}
          >
            Add Question
          </CabButton>
        </StyledQuestionContainer>
      )}
      {openEditModal && (
        <ThemeProvider theme={theme}>
          <QuestionModal
            open={openEditModal}
            questions={questions}
            questionId={editQuestion}
            onClose={() => setOpenEditModal(false)}
            currentMeetingId={currentMeetingId}
            isPoll={isPoll}
            meetingField={meetingFields}
            hasSingleSelectOption={hasSingleSelectOption}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default QuestionChips;