import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { DateTime } from "luxon";
import classes from "./MeetingErrors.module.css";
import { Calendar, Meeting, MeetingHoldEventErrorResolution, MeetingHoldEventError } from "../../store";

interface MeetingErrorProps {
  meeting: Meeting | null
  meetingErrors: MeetingHoldEventError[]
  handleResolution: (context: MeetingHoldEventErrorResolution) => void
  calendars: Calendar[]
}

const MeetingErrors = (
  { meeting, handleResolution, calendars, meetingErrors }: MeetingErrorProps
): ReactElement => {
  return (
    <Grid container className={classes.errorContainerWrapper}>
      <Grid item xs={12}>
        <span className={classes.title}>Errors</span>
      </Grid>
      <Grid container className={classes.errorWrapper}>
        {meeting !== null && meetingErrors ?
          <>
            {meetingErrors.map((error: MeetingHoldEventError, idx: number) => {
              const calendar: Calendar | undefined = calendars.reduce(
                (previous: Calendar, current: Calendar) => {
                  if (current.calendar_id === error.calendarId) {
                    return current;
                  }
                  return previous;
                });
              const calendarName = calendar ? 
                (calendar.additionalCalendarEmail ? calendar.additionalCalendarEmail.name : calendar.summary) : (
                  meeting?.booking_calendar ? 
                    calendars[meeting?.booking_calendar].summary || "No Name" : "No Name"
                );
              return (
                <ErrorCard
                  key={idx} {...error}
                  handleResolution={handleResolution}
                  leaderName={calendarName}
                  meetingId={meeting.id}
                />
              );
            })}
          </>
          :
          <span>
            No meeting specified
          </span>
        }
      </Grid>
    </Grid>
  );
};

interface ErrorCardProps extends MeetingHoldEventError {
  meetingId: number
  handleResolution: (context: MeetingHoldEventErrorResolution) => void
}


const prettyPrintErrorCode = (errorCode: string, action: string | null): [string, string] => {
  const TOKEN_DOES_NOT_EXIST = (
    "Reason: Calendar account not connected - please re-authenticate with your calendar account."
  );
  const MICROSOFT_INVALID_USER_GRANT = "Reason: Cabinet does not have permission to modify your calendar.";
  const MICROSOFT_ITEM_NOT_FOUND = (
    "Reason: Event was not found on the calendar, or you no longer have access to the calendar."
  );
  const ALL_OTHER_ERRORS = "Reason: Unknown - contact help@joincabinet.com for assistance.";
  
  let message = ALL_OTHER_ERRORS;
  let action_message = "added / removed from calendar";
  switch (errorCode) {
    case "microsoft_item_not_found":
      message = MICROSOFT_ITEM_NOT_FOUND;
      break;
    case "microsoft_invalid_user_grant":
      message = MICROSOFT_INVALID_USER_GRANT;
      break;
    case "token_does_not_exist":
      message = TOKEN_DOES_NOT_EXIST;
      break;
  }
  switch (action) {
    case "update_or_create_executive_hold":
      action_message = "added to calendar";
      break;
    case "delete_executive_hold":
      action_message = "removed from calendar";
      break;
  }

  return [`Hold was not ${action_message}`, message];
};

const formatIsoDateTimeString = (date: string): string => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
};




const ErrorCard = (
  {
    meetingId,
    meetingSlotCalendarEventId,
    leaderName,
    error_code,
    action,
    error_timestamp,
    meetingName,
    resolved,
    handleResolution,
    meetingSlotStart,
    meetingSlotEnd,
    ...props
  }: ErrorCardProps
) => {
  const meetingDetails = (
    `Calendar: ${leaderName} | Meeting Name: ${meetingName} | Time: ${
      meetingSlotStart ? formatIsoDateTimeString(meetingSlotStart) : "Unknown - Slot Deleted"
    }`
  );

  const [error, resolution_text] = React.useMemo(
    () => prettyPrintErrorCode(
      error_code,
      action,
    ),
    [error_code, action]
  );

  return (
    <Grid item xs={12} className={classes.errorCard}>
      <Grid item xs={8} className={classes.errorDetails}>
        <Grid item xs={12} className={classes.errorNameWrapper}>
          <div className={classes.errorTitle}>Error: {error}</div>
          <div>Date: {formatIsoDateTimeString(error_timestamp)}</div>
           
        </Grid>
        <Grid item xs={12} className={classes.errorNameWrapper}>
          <div>{resolution_text}</div>
        </Grid>
        <Grid item xs={12} >
          <span>
            {meetingDetails}
          </span>
        </Grid>
        
      </Grid>
      <Grid item xs={4} className={classes.errorResolution}>
        <FormControlLabel
          label="Mark as resolved"
          control={
            <Checkbox
              checked={resolved}
              onChange={() => handleResolution({meetingId, meetingSlotCalendarEventId})}
            />
          }
          labelPlacement="end"
        />
      </Grid>
    </Grid>
  );
};

export default MeetingErrors;
