import { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Calendar, Leader, User } from "../../../store";
import { CabAvatar, CabButton, CabExecPicker, CabIcon, CabModal, CabTooltip } from "@CabComponents";
import LeaderEmailsModal from "../LeaderEmailsModal";
import CreateMeetingButton from "../CreateMeetingButton/CreateMeetingButton";
import { useCabinetText } from "../../../CabinetContext";
import { IoHelpCircleOutline } from 'react-icons/io5';
import { getLeaderIconSrc } from "../../../utils/leaderUtils";


export interface ScheduleHeaderProps {
  user: User;
  leadersForScheduling: Leader[];
  calendars: Calendar[];
  execPickerDisabled: boolean;
  onUpdateLeaders: (leaders: Leader[]) => Promise<void>;
  selectedLeaders: number[];
  creatingMeeting: boolean;
  currentMeetingId?: number;
  onCancel: () => void;
  onLeaderSelect: (ids: number[]) => void;
  opencalendarsModal: () => void;
  onCreateMeeting: () => void;
  onCreateReusableMeeting: () => void;
  onCreatePoll: () => void;
}


const ScheduleHeader = ({ user, selectedLeaders, leadersForScheduling, calendars, creatingMeeting, 
  currentMeetingId, onCancel, onLeaderSelect, 
  execPickerDisabled, onUpdateLeaders, opencalendarsModal, onCreateMeeting, onCreateReusableMeeting, onCreatePoll
}: ScheduleHeaderProps): ReactElement => {
  const [execsToUpdate, setExecsToUpdate] = useState<Leader[]>([]);
  const [execsToBeUpdatedOnMeeting, setExecsToBeUpdatedOnMeeting] = useState<Leader[]>([]);
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);

  const [selectPlaceholder] = useCabinetText(['schedule-teammember-select-placeholder']);
  
  const currLeaders = useMemo(() => (
    leadersForScheduling.filter(l => selectedLeaders.includes(l.id))
  ), [leadersForScheduling, selectedLeaders]);

  const addedLeaders = useMemo(() => (
    execsToBeUpdatedOnMeeting.filter(l => !currLeaders.find(cl => cl.id === l.id))
  ), [currLeaders, execsToBeUpdatedOnMeeting]);

  const removedLeaders = useMemo(() => (
    currLeaders.filter(l => !execsToBeUpdatedOnMeeting.find(cl => cl.id === l.id))
  ), [currLeaders, execsToBeUpdatedOnMeeting]);

  const handleLeaderSelect = (leaderIds: number[]) => {
    if (currentMeetingId) {
      setExecsToBeUpdatedOnMeeting(leadersForScheduling.filter(l => leaderIds.includes(l.id)));
    } else if (creatingMeeting) {
      handleLeaderSelectForOpenMeeting(leaderIds);
    } else {
      onLeaderSelect(leaderIds);
    }
  };

  const handleLeaderSelectForOpenMeeting = (leaderIds: number[]) => {
    const leadersWithoutEmail = leadersForScheduling.filter(l => Array.isArray(leaderIds) && leaderIds.includes(l.id))
      .filter(l => !l.email);
    const leadersWithEmail = leadersForScheduling.filter(l => Array.isArray(leaderIds) && leaderIds.includes(l.id))
      .filter(l => l.email);

    if (leadersWithEmail.length > 0) {
      onLeaderSelect(leadersWithEmail.map(l => l.id));
    }

    if (leadersWithoutEmail.length > 0) {
      setExecsToUpdate(leadersWithoutEmail);
    }
  };

  const confirmMeetingLeaderChange = () => {
    handleLeaderSelectForOpenMeeting(execsToBeUpdatedOnMeeting.map(l => l.id));
    setExecsToBeUpdatedOnMeeting([]);
  };

  const cancelMeetingLeaderChange = () => {
    setExecsToBeUpdatedOnMeeting([]);
  };

  useEffect(() => {
    if (creatingMeeting) {
      const leadersWithoutEmail = leadersForScheduling.filter(l => selectedLeaders.includes(l.id))
        .filter(l => !l.email);

      if (leadersWithoutEmail.length > 0) {
        setExecsToUpdate(leadersWithoutEmail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingMeeting]);

  return (
    <Box width="100%" justifyItems="space-between">
      <Grid container height="55px" display="flex" alignItems="center">
        <Grid item xs={2} sx={theme => ({ [theme.breakpoints.up('sm')]: { display: 'none' } })} />
        <Grid item xs={6} sm={8}>
          <CabExecPicker<number[]>
            value={selectedLeaders}
            options={leadersForScheduling.map(leader => {
              const numCals = calendars.filter(c => c.leaders.includes(leader.id)).length;
              return {
                value: leader.id,
                label: `${leader.first_name} ${leader.last_name}`,
                icon: <CabAvatar
                  src={getLeaderIconSrc(leader)}
                  color={leader.color}
                  name={`${leader.first_name}
                  ${leader.last_name}`}
                  size="small"
                />,
                labelSecondary: `${numCals} calendar${numCals === 1 ? '' : 's'}`
              };
            })}
            onCloseWithValues={v => Array.isArray(v) && handleLeaderSelect(v)}
            multiple
            hasDoneButton
            placeholder={selectPlaceholder}
            disabled={execPickerDisabled}
            opencalendarsModal={opencalendarsModal}
            sx={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-end', gap: 1 }}>
            {creatingMeeting || currentMeetingId ? (
              <CabButton
                buttonType="primary"
                sx={theme => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}
                onClick={onCancel}
              >
                {currentMeetingId ? 'Done' : 'Cancel'}
              </CabButton>
            ) : (
              <>
                <CreateMeetingButton
                  buttonTitle="Create"
                  buttonId="ScheduleHeader-create-meeting-btn"
                  onCreateMeetingOverride={onCreateMeeting}
                  onCreateReusableMeetingOverride={onCreateReusableMeeting}
                  onCreatePollOverride={onCreatePoll}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      {execsToUpdate.length > 0 && (
        <>
          <LeaderEmailsModal
            isOpen={execsToUpdate.length > 0}
            leaders={execsToUpdate}
            onEmailsSubmitted={async (leaders) => {
              await onUpdateLeaders(leaders);
              onLeaderSelect(Array.from(new Set([...selectedLeaders, ...leaders.map(l => l.id)])));
              setExecsToUpdate([]);
            }}
            onCancel={() => {
              setShowConfirmCancelDialog(true);
            }}
          />
          <CabModal
            open={showConfirmCancelDialog}
            onClose={() => setShowConfirmCancelDialog(false)}
            isAlert
            title="Are you sure?"
            text="Any executives without an email will be removed from this meeting."
            actionButtons={<>
              <CabButton buttonType='tertiary' color='primary' onClick={() => setShowConfirmCancelDialog(false)}>
                Add Emails
              </CabButton>
              <CabButton onClick={() => {
                onLeaderSelect(selectedLeaders.filter(lId => !execsToUpdate.map(l => l.id).includes(lId)));
                setExecsToUpdate([]);
                setShowConfirmCancelDialog(false);
              }}>
                Remove Executives
              </CabButton>
            </>}
          />
        </>
      )}

      {execsToBeUpdatedOnMeeting.length > 0 && (addedLeaders.length > 0 || removedLeaders.length > 0) && (
        <ConfirmMeetingLeaderChangeModal
          open={execsToBeUpdatedOnMeeting.length > 0}
          onCancel={cancelMeetingLeaderChange}
          onConfirm={confirmMeetingLeaderChange}
          addedLeaders={addedLeaders}
          removedLeaders={removedLeaders}
        />
      )}
    </Box>
  );
};

export default ScheduleHeader;


const ConfirmMeetingLeaderChangeModal = ({ open, onCancel, onConfirm, addedLeaders, removedLeaders }: {
  open: boolean; onCancel: () => void; onConfirm: () => void; addedLeaders: Leader[]; removedLeaders: Leader[]
}) => {

  return (
    <CabModal
      open={open}
      onClose={onCancel}
      isAlert
      title="Change Meeting Teammates"
      text="The following changes will be made to your meeting:"
      actionButtons={<Box width="100%" display="flex" justifyContent="space-between" padding={1}>
        <CabTooltip
          title="Don't want to update this meeting? Close the meeting panel on the right before changing teammates"
          wrapWithSpan
          placement="bottom-start"
        >
          <CabIcon Icon={IoHelpCircleOutline} size="large" />
        </CabTooltip>
        <Box display="flex" gap={1}>
          <CabButton buttonType='tertiary' color='primary' onClick={onCancel}>
            No
          </CabButton>
          <CabButton onClick={onConfirm}>
            Yes
          </CabButton>
        </Box>
      </Box>}
    >
      {removedLeaders.length > 0 && (
        <>
          <Typography fontWeight="bold" marginTop={2} marginBottom={1}>Teammates Removed</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {removedLeaders.map(l => (
              <Box display="flex" key={l.id} alignItems="center" gap={1}>
                <CabAvatar 
                  name={`${l.first_name} ${l.last_name}`}
                  src={l.pic_url}
                  color={l.color}
                  size="medium"
                />
                <Typography>{l.first_name} {l.last_name}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      {addedLeaders.length > 0 && (
        <>
          <Typography fontWeight="bold" marginTop={2} marginBottom={1}>Teammates Added</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {addedLeaders.map(l => (
              <Box display="flex" key={l.id} alignItems="center" gap={1}>
                <CabAvatar 
                  name={`${l.first_name} ${l.last_name}`}
                  src={l.pic_url}
                  color={l.color}
                  size="medium"
                />
                <Typography>{l.first_name} {l.last_name}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </CabModal>
  );
};
