import { CabButton, CabDropdown, CabIcon, CabTooltip } from "@CabComponents";
import CabMeetingLocationDropdown from "@CabComponents/CabMeetingLocationDropdown";
import { Box, FormControl, FormLabel, Grid } from "@mui/material";
import { uniqBy } from "lodash-es";
import { useCallback, useMemo } from "react";
import { Control, Controller, SubmitHandler, UseFormReturn, useFieldArray } from "react-hook-form";
import {
  Calendar, LeaderList, Meeting, MeetingRoom, MeetingUpdate, PresetLocations, User, ZoomSettings,
} from "../../../store";
import { getTemplateVars } from "../../../utils/scheduleUtils";
import { LocationsFormInput } from "./MeetingSettings";
import { transformMarkupToSimpleTemplate } from "@CabComponents/CabTextTokenInput";
import { IoAddOutline, IoCloseOutline } from "react-icons/io5";


interface Props {
  isOwner: boolean;
  meeting: Partial<Meeting> & { id: number; };
  zoomSettings: {
    [settingsId: string]: ZoomSettings;
  };
  user: User | null | undefined;
  leaders: LeaderList;
  leaderCalendarOptions: Calendar[];
  onAddZoom: () => void;
  calendars: Calendar[];
  meetingRooms: { [id: string]: MeetingRoom }
  presetLocations: PresetLocations
  onOpenQuestionModal: () => void;
  control: Control;
  locationsForm: UseFormReturn<LocationsFormInput, undefined>;
  onSubmit: (meeting: MeetingUpdate, files?: File[]) => Promise<void>;
  noSelectedLeaders?: boolean;
  locations?: ({
    conference?: { provider: number | null; leader: number | null },
    locationText?: string;
    room?: number;
    presetLocation?: number;
    none?: boolean;
  })[];
}

const CalendarAndLocation = ({
  isOwner, meeting, calendars, leaderCalendarOptions, zoomSettings, user, leaders, meetingRooms, 
  presetLocations, onAddZoom, onOpenQuestionModal, control, locations, locationsForm, onSubmit, noSelectedLeaders
}: Props) => {
  const templateVars = useMemo(() => {
    return getTemplateVars(meeting.questions);
  }, [meeting.questions]);

  const {
    fields: locationFields, append, remove,
  } = useFieldArray({ control: locationsForm.control, name: 'locations' });

  let calendarOptions = leaderCalendarOptions.map(cal => ({
    value: cal.id, label: cal.summary
  }));

  // in case user does not have access to this calendar we must also include the current meeting calendar as an option,
  // then dedupe
  if (meeting.calendar_info?.calendar_name) {
    calendarOptions = uniqBy([
      ...calendarOptions,
      { value: meeting.calendar_info.id, label: meeting.calendar_info.calendar_name },
    ], cal => cal.value);
  }

  const calendar = useMemo(
    () => calendars.find(
      (cal) => meeting.booking_calendar === cal.id), [meeting.booking_calendar, calendars]
  );

  const handleLocationsSubmit: SubmitHandler<LocationsFormInput> = useCallback(async (data) => {
    const [conference] = data.locations?.filter(loc => loc?.conference) || [];
    const conferenceProvider: number | null | undefined = conference?.conference?.provider || null;
    const conferenceLeader: number | null | undefined = conference?.conference?.leader || null;

    const submitLocations = data.locations?.filter(loc => loc?.locationText != null)
      .map(loc => loc?.locationText ? transformMarkupToSimpleTemplate(loc.locationText) : '') as string[];
    const rooms = data.locations?.filter(loc => loc?.room != null && loc.room !== -1)
      .map(loc => loc?.room) as number[];
    const submitPresetLocations = data.locations?.filter(loc => loc?.presetLocation != null)
      .map(loc => loc?.presetLocation) as number[];

    await onSubmit({
      id: meeting.id,
      conference_provider: conferenceProvider,
      conference_leader: conferenceLeader,
      locations: submitLocations,
      rooms,
      location_presets: submitPresetLocations,
    }, undefined);

  }, [meeting.id, onSubmit]);

  const showTokenInputs = !meeting.is_poll;

  if (!user) return null;

  return (
    <Grid container>
      <Grid xs={12} item>
        <Controller name="selectedCalendar" control={control} render={({ field: { ref, ...field } }) => (
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Calendar</FormLabel>
            <CabTooltip
              title={!isOwner ? 'Only meeting owner may edit this field' 
                : noSelectedLeaders ? 'A leader must be attached to edit this field' : ''}
              placement="top"
              wrapWithSpan
            >
              <CabDropdown<number>
                {...field}
                value={noSelectedLeaders ? undefined : field.value}
                options={calendarOptions}
                disabled={!isOwner || noSelectedLeaders}
                sx={{ width: '100%' }}
              />
            </CabTooltip>
          </FormControl>
        )} />
      </Grid>

      <Grid xs={12} container item marginTop={2} marginBottom={'2px'}>
        <FormLabel>Location(s) and Conference Tool</FormLabel>
      </Grid>
      <Grid xs={12} container item spacing={2}>
        {locationFields.map((locField, idx) => (
          <Controller
            key={locField.id}
            name={`locations.${idx}`}
            control={locationsForm.control}
            render={({ field: { ref, ...field } }) => (
              <>
                <Grid xs={isOwner && locations && locations.length > 1 ? 11.3 : 12} item>
                  <CabTooltip
                    title={!isOwner ? 'Only meeting owner may edit this field' 
                      : noSelectedLeaders ? 'A leader must be attached to edit this field' : ''}
                    placement="top"
                    wrapWithSpan
                  >
                    <CabMeetingLocationDropdown
                      secondaryInputOnNewLine
                      {...field}
                      value={noSelectedLeaders ? {} : field.value}
                      onChange={loc => {
                        field.onChange(loc);
                        if (!('locationText' in loc) && loc.room !== -1) {
                          locationsForm.handleSubmit(handleLocationsSubmit)();
                        }
                      }}
                      onBlurOtherField={() => locationsForm.handleSubmit(handleLocationsSubmit)()}
                      disabled={!isOwner || noSelectedLeaders}
                      showTokenInputs={showTokenInputs}
                      meeting={meeting}
                      meetingBookingCalendar={calendar}
                      user={user}
                      leaders={leaders}
                      zoomSettings={zoomSettings}
                      isOwner={isOwner}
                      presetLocations={presetLocations}
                      meetingRooms={meetingRooms}
                      onAddZoom={onAddZoom}
                      onAddQuestion={meeting.is_reusable || meeting.is_poll ?
                        () => onOpenQuestionModal()
                        : undefined}
                      templateVars={templateVars}
                      // only allow one virtual conference location
                      disableConferenceOptionsTooltip={locations?.some(loc => loc?.conference)
                        ? 'Only one conference integration can be set per meeting' : undefined}
                      disabledLocationPresetIds={locations?.filter(loc => loc?.presetLocation)
                        .map(loc => loc?.presetLocation) as number[]}
                      disabledMeetingRoomIds={locations?.filter(loc => loc?.room).map(loc => loc?.room) as number[]}
                    />
                  </CabTooltip>
                </Grid>

                {isOwner && locations && locations.length > 1 && (
                  <Grid xs={0.7} item marginLeft={-2.2}>
                    <CabIcon
                      Icon={IoCloseOutline}
                      onClick={() => {
                        remove(idx);
                        locationsForm.handleSubmit(handleLocationsSubmit)();
                      }}
                      sx={{ fontSize: 26, paddingTop: 0.8, }}
                    />
                  </Grid>
                )}
              </>
            )} />
        ))}

        <Grid xs={12} item>
          <Box sx={{ width: "100%" }} display="flex" flexDirection="row">
            {isOwner && (
              <CabButton
                buttonType="tertiary"
                // disabled={saving}
                onClick={() => append({ none: true })}
                icon={<CabIcon Icon={IoAddOutline} />}
                sx={{ border: 1, borderColor: 'transparent' }}
              >
                Add Meeting Location
              </CabButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CalendarAndLocation;
