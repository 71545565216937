import { CabIcon } from '@CabComponents/CabIcon';
import { CabList } from '@CabComponents/CabList';
import CabAvatar from '@CabComponents/CabAvatar';
import { Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { MeetingLeader, MeetingLeaderInfo, NormalizedExternalParticipant } from '../../../store';
import { getLeaderIconSrc } from '../../../utils/leaderUtils';
import { IoInformationCircle, IoPersonCircleOutline } from 'react-icons/io5';

interface Props {
  leaderInfo: MeetingLeaderInfo[];
  additionalParticipants: NormalizedExternalParticipant[];
  meetingLeaders: MeetingLeader[];
  onRemoveLeader: (id: number) => void;
  handleDeleteAttendee: (id: number) => void;
  isEditable: boolean;
  onClickParticipant?: () => void;
}

const MeetingParticipants = ({ leaderInfo, onRemoveLeader, meetingLeaders, additionalParticipants, handleDeleteAttendee,
  isEditable, onClickParticipant
} : Props): ReactElement => {
  return (
    <Box>
      <Typography variant='h2' marginBottom="4px">People & Calendars</Typography>
      <CabList
        items={[
          ...(leaderInfo).map((leader, i, ldrs) => {
            const leaderFound = meetingLeaders.find(l => l.leader === leader.id);
            return {
              value: leader.meeting_leader_id,
              key: `leader-${leader.id}`,
              onClick: onClickParticipant,
              onDeleteClick: () => onRemoveLeader(leader.meeting_leader_id),
              tooltip: !leaderFound
                ? 'You do not have access to this participant'
                : '',
              label: (
                <Box display="flex" justifyContent="space-between">
                  <span>{leader.first_name} {leader.last_name}</span>
                  {!leaderFound && (
                    <CabIcon
                      Icon={IoInformationCircle}
                      sx={{ marginTop: '2px', fontSize: 18 }}
                    />
                  )}
                </Box>
              ),
              editable: ldrs.length > 1 && isEditable,
              icon: leaderFound && <CabAvatar
                src={getLeaderIconSrc(leaderFound)}
                color={leaderFound.color}
                name={`${leaderFound.first_name} ${leaderFound.last_name}`}
                size="small"
              />
            };
          }),
          ...((additionalParticipants || []).map(participant => ({
            value: participant.id,
            key: `${participant.email}-${participant.id}`,
            label: participant.name || participant.email,
            onClick: onClickParticipant,
            onDeleteClick: () => {
              handleDeleteAttendee(participant.id);
            },
            editable: true,
            icon: <CabIcon Icon={IoPersonCircleOutline} sx={{ fontSize: 28, marginLeft: '-2px' }} />
          }))),
        ]}
      />
    </Box>
  );
};

export default MeetingParticipants;
