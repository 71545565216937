import { Fragment, useEffect, useMemo, useState } from "react";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import {
  AvatarGroup,
  Box, Divider, FormLabel, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import { CabCheckbox } from "@CabComponents/CabCheckbox";
import { Controller, useForm } from "react-hook-form";
import CabSpinner from "@CabComponents/CabSpinner";
import { emailRegex } from "../../../constants";
import { Calendar, Leader } from "../../../store";
import colors from "../../../colors";
import CabAvatar from "@CabComponents/CabAvatar";
import { isEqual } from "lodash-es";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { getLeaderIconSrc } from "../../../utils/leaderUtils";
import { IoCreateOutline, IoSearch } from "react-icons/io5";


type AddLeadersOrCalendarProps = {
  isOpen: boolean;
  onDone: (selectedLeaders?: number[], justCalendar?: boolean) => Promise<void>;
  onCancel: () => void;
  leaders: Leader[];
  calendar: { id: number; title: string };
};

const AddLeadersOrCalendar = ({ isOpen, onDone, onCancel, leaders, calendar }: AddLeadersOrCalendarProps) => {
  const [saving, setSaving] = useState(false);
  const [selectedLeaders, setSelectedLeaders] = useState<Set<number>>(new Set());
  const [justCalendar, setJustCalendar] = useState(false);

  const handleCancel = () => {
    setSelectedLeaders(new Set());
    setJustCalendar(false);
    onCancel();
  };

  const handleDone = async () => {
    setSaving(true);
    await onDone([...selectedLeaders], justCalendar);
    setSaving(false);
    setSelectedLeaders(new Set());
    setJustCalendar(false);
  };

  const handleCheckLeader = (leaderId: number) => {
    setJustCalendar(false);
    const newSelectedLeaders = new Set(selectedLeaders);
    if (selectedLeaders.has(leaderId)) {
      newSelectedLeaders.delete(leaderId);
    } else {
      newSelectedLeaders.add(leaderId);
    }
    setSelectedLeaders(newSelectedLeaders);
  };

  const handleCheckJustCalendar = () => {
    setSelectedLeaders(new Set());
    setJustCalendar(!justCalendar);
  };

  return (
    <CabModal
      open={isOpen}
      onClose={onDone}
      title="Add Teammate or Calendar"
      text={`This calendar is associated with one or more teammates.
      Would you like to add a teammate to this meeting or just the calendar?`}
      actionButtons={(<>
        <CabButton buttonType="tertiary" onClick={handleCancel} disabled={saving}>
          Cancel
        </CabButton>
        <CabButton onClick={handleDone} disabled={saving}>
          {saving ? <CabSpinner scale={1} /> : 'Done'}
        </CabButton>
      </>)}
    >
      <List dense>
        {leaders.map((leader) => (
          <ListItem
            key={leader.id}
            disablePadding
            onClick={() => handleCheckLeader(leader.id)}
          >
            <ListItemButton role={undefined} dense>
              <ListItemIcon sx={{ minWidth: 22 }}>
                <Box display="flex" flexDirection="row">
                  <CabCheckbox
                    checked={selectedLeaders.has(leader.id)}
                    overrides={{
                      edge: 'start',
                      disableRipple: true,
                    }}
                  />
                  <CabAvatar
                    key={leader.id}
                    src={getLeaderIconSrc(leader)}
                    color={leader.color}
                    name={`${leader.first_name}
                    ${leader.last_name}`}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  />
                </Box>
              </ListItemIcon>
              <ListItemText primary={`${leader.first_name} ${leader.last_name}`} sx={{ marginLeft: 1 }} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem
          disablePadding
          onClick={handleCheckJustCalendar}
        >
          <ListItemButton role={undefined} dense>
            <ListItemIcon sx={{ minWidth: 22 }}>
              <Box display="flex" flexDirection="row">
                <CabCheckbox
                  checked={justCalendar}
                  overrides={{
                    edge: 'start',
                    disableRipple: true,
                  }}
                />
              </Box>
            </ListItemIcon>
            <ListItemText primary={`Just add "${calendar.title}"`} />
          </ListItemButton>
        </ListItem>

      </List>
    </CabModal>
  );
};

export type Props = {
  isOpen: boolean;
  onDone: (selectedCalendars: number[]) => void;
  onCancel: () => void;
  calendars: {
    id: number;
    calendar_access_id: number
    title: string;
    subtitle?: string;
    editable: boolean;
    leaders?: Leader[];
  }[];
  defaultSelectedCalendars: number[];
  onAddCalendar: (email: string, name: string) => Promise<Calendar['id']|undefined>;
  onUpdateCalendar: (id: number, name: string) => Promise<void>;
  onSelectLeaders: (leaders: number[]) => void;
  loading: boolean;
};

const AdditionalCalendars = ({
  isOpen, onDone, onCancel, calendars, defaultSelectedCalendars, onAddCalendar, onUpdateCalendar, loading,
  onSelectLeaders
}: Props) => {
  const [addingCalendar, setAddingCalendar] = useState(false);
  const [editingCalendar, setEditingCalendar] = useState<number|null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [saving, setSaving] = useState(false);
  const [selectedCalendars, setSelectedCalendars] = useState(new Set(defaultSelectedCalendars));
  const [confirmLeadersOrCalendar, setConfirmLeadersOrCalendar] = useState<number | null>(null);
  const [calendarNotFound, setCalendarNotFound] = useState(false);


  const addEditForm = useForm({ defaultValues: { email: '', name: '' } });

  const initialCalendars = useMemo(() => {
    return calendars
      .map(cal => ({ ...cal, checked: defaultSelectedCalendars.includes(cal.calendar_access_id) }));
  }, [defaultSelectedCalendars, calendars]);

  const allCalendars = initialCalendars
    .map(cal => ({ ...cal, checked: new Set(defaultSelectedCalendars).has(cal.calendar_access_id) }))
    .sort((a, b) => a.leaders?.length ? 1 : -1)
    .sort((a, b) => a.checked ? -1 : 1);

  const filteredCalendars = allCalendars
    .filter(cal => (
      cal.title.toLowerCase().includes(searchValue.toLowerCase())
        || cal.subtitle?.toLowerCase().includes(searchValue.toLowerCase())
    ));
  
  useEffect(() => {
    setSelectedCalendars(new Set(defaultSelectedCalendars));
  }, [defaultSelectedCalendars]);

  const handleNewCalendarOptionClick = () => {
    setAddingCalendar(true);
    if (emailRegex.test(searchValue)) {
      addEditForm.setValue('email', searchValue);
    } else {
      addEditForm.setValue('name', searchValue);
    }
    setSearchValue('');
  };

  const handleEditCalendar = (id: number) => {
    setEditingCalendar(id);
    const cal = calendars.find(c => c.calendar_access_id === id);
    if (cal?.subtitle) {
      addEditForm.setValue('email', cal.subtitle);
      addEditForm.setValue('name', cal.title);
    } else {
      setEditingCalendar(null);
    }
  };

  const handleAddCalendar = async () => {
    setSaving(true);
    await addEditForm.handleSubmit(async ({ email, name }) => {
      const calId = await onAddCalendar(email, name);
      if (calId != null) {
        setSelectedCalendars(new Set([...selectedCalendars, calId]));
        setAddingCalendar(false);
        addEditForm.reset();
        setCalendarNotFound(false);
      } else {
        setCalendarNotFound(true);
      }
    })();
    setSaving(false);
  };

  const handleCancelAddCalendar = () => {
    setAddingCalendar(false);
    addEditForm.reset();
    setCalendarNotFound(false);
  };

  const handleCancelEditCalendar = () => {
    setEditingCalendar(null);
    addEditForm.reset();
    setCalendarNotFound(false);
  };

  const handleSaveEditCalendar = async () => {
    setSaving(true);
    if (editingCalendar) {
      await addEditForm.handleSubmit(({ name }) => onUpdateCalendar(editingCalendar, name))();
    }
    setEditingCalendar(null);
    setSaving(false);
    addEditForm.reset();
    setCalendarNotFound(false);
  };

  const handleCheckCalendar = (checked: boolean, cal: Props['calendars'][number]) => {
    if (checked) {
      if (cal.leaders?.length) {
        setConfirmLeadersOrCalendar(cal.calendar_access_id);
      } else {
        setSelectedCalendars(new Set([...selectedCalendars, cal.calendar_access_id]));
      }
    } else {
      const newSelectedCalendars = new Set(selectedCalendars);
      newSelectedCalendars.delete(cal.calendar_access_id);
      setSelectedCalendars(newSelectedCalendars);
    }
  };

  const handleDone = async () => {
    setSaving(true);
    await onDone([...selectedCalendars]);
    setSaving(false);
  };

  const handleAddLeadersOrCalendar = async (selectedLeaders?: number[], calendarId?: number) => {
    if (selectedLeaders?.length) {
      onSelectLeaders(selectedLeaders);
    } else if (calendarId != null) {
      setSelectedCalendars(new Set([...selectedCalendars, calendarId]));
    }
    setConfirmLeadersOrCalendar(null);
  };

  if (!isOpen) return null;

  return (
    <>
      <CabModal
        open={isOpen}
        onClose={addingCalendar
          ? () => setAddingCalendar(false)
          : editingCalendar ? () => setEditingCalendar(null) : handleDone}
        title={addingCalendar
          ? 'Add Calendar'
          : editingCalendar ? 'Edit Calendar Name' : 'Select Calendars to View'}
        closeOnBackdropClick
        text={"Add additional calendars to include in your meeting"}
        closeIcon
        actionButtons={addingCalendar ? (<>
          <CabButton buttonType="tertiary" onClick={handleCancelAddCalendar} disabled={saving}>
            Cancel
          </CabButton>
          <CabButton onClick={handleAddCalendar} disabled={saving || !addEditForm.formState.isValid}>
            {saving ? <CabSpinner scale={1} /> : 'Add Calendar'}
          </CabButton>
        </>) : editingCalendar ? (<>
          <CabButton buttonType="tertiary" onClick={handleCancelEditCalendar} disabled={saving}>
            Cancel
          </CabButton>
          <CabButton onClick={handleSaveEditCalendar} disabled={saving || !addEditForm.formState.isValid}>
            {saving ? <CabSpinner scale={1} /> : 'Save Changes'}
          </CabButton>
        </>) : (<>
          <CabButton buttonType="tertiary" onClick={onCancel}>
            Cancel
          </CabButton>
          <CabButton
            onClick={handleDone}
            disabled={loading || saving || isEqual(selectedCalendars, new Set(defaultSelectedCalendars))}
          >
            {saving ? <CabSpinner scale={1} /> : 'Done'}
          </CabButton>
        </>)}
      >
        {addingCalendar ? (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column">
              <FormLabel required>Email</FormLabel>
              <Controller control={addEditForm.control} name="email" rules={{ required: true, pattern: emailRegex }}
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} placeholder="Calendar email" />
                )} />
            </Box>

            <Box display="flex" flexDirection="column">
              <FormLabel>Calendar Name</FormLabel>
              <Controller control={addEditForm.control} name="name" rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} placeholder="Enter the participant's name" />
                )} />
            </Box>
            {calendarNotFound && <Box>
              <Typography sx={{color: colors.redError}}>Calendar Not Found</Typography>
            </Box> }
          </Box>
        ) : editingCalendar ? (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" flexDirection="column">
              <FormLabel>Email</FormLabel>
              <Controller control={addEditForm.control} name="email" render={({ field: { ref, ...field } }) => (
                <CabTextInput {...field} disabled />
              )} />
            </Box>

            <Box display="flex" flexDirection="column">
              <FormLabel>Calendar Name</FormLabel>
              <Controller control={addEditForm.control} name="name" rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} placeholder="Enter the participant's name" />
                )} />
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={1} marginTop={1}>
            <CabTextInput
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder="Search for calendars by name or email"
              leftIcon={<CabIcon Icon={IoSearch} />}
            />
            {(filteredCalendars.length > 0 || searchValue) && <Divider />}
            <List dense>
              {filteredCalendars.map(cal => (
                <Fragment key={cal.calendar_access_id}>
                  <ListItem
                    disablePadding
                    onClick={() => handleCheckCalendar(!selectedCalendars.has(cal.calendar_access_id), cal)}
                    secondaryAction={(
                      <Box display="flex" flexDirection="row">
                        {cal.leaders?.length ? (
                          <AvatarGroup max={3} spacing={5} 
                            sx={{'& .MuiAvatar-root': { width: 24, height: 24, fontSize: 14 }}}>
                            {cal.leaders.map(leader => (
                              <CabAvatar
                                key={leader.id}
                                src={getLeaderIconSrc(leader)}
                                color={leader.color}
                                name={`${leader.first_name}
                                ${leader.last_name}`}
                                size="small"
                              />
                            ))}
                          </AvatarGroup>
                        ) : null}
                        {cal.editable && (
                          <IconButton edge='end' onClick={() => handleEditCalendar(cal.calendar_access_id)}>
                            <CabIcon Icon={IoCreateOutline} />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  >
                    <ListItemButton role={undefined} dense>
                      <ListItemIcon sx={{ minWidth: 22 }}>
                        <CabCheckbox
                          checked={selectedCalendars.has(cal.calendar_access_id)}
                          overrides={{
                            edge: 'start',
                            disableRipple: true,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={cal.title} 
                        secondary={
                          <CabTooltip title={cal.subtitle} wrapWithSpan>
                            {cal.subtitle}
                          </CabTooltip>
                        } 
                        secondaryTypographyProps={{
                          variant:"body2",
                          color: colors.black600,
                          maxWidth: cal.leaders?.length ? 270 : 300,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        sx={{ margin: 0 }} 
                      />
                    </ListItemButton>
                  </ListItem>
                  <AddLeadersOrCalendar
                    isOpen={confirmLeadersOrCalendar === cal.calendar_access_id}
                    leaders={cal.leaders || []}
                    calendar={cal}
                    onCancel={() => setConfirmLeadersOrCalendar(null)}
                    onDone={(selectedLeaders, justCalendar) => (
                      handleAddLeadersOrCalendar(selectedLeaders, justCalendar ? cal.calendar_access_id : undefined)
                    )}
                  />
                </Fragment>
              ))}
              {searchValue && (
                <Box marginTop={2}>
                  <Typography variant='body2' fontStyle='italic'>
                    Can't find who you're looking for? Add them here:
                  </Typography>
                  <ListItem disablePadding sx={{ border: `1px solid ${colors.black200}`, borderRadius: 1}}>
                    <ListItemButton onClick={handleNewCalendarOptionClick}>
                      <ListItemText 
                        primary={
                          <Box display='flex' gap={.25}> 
                            <Typography>+</Typography> 
                            <Typography fontWeight='bold'>Add</Typography> 
                            <Typography>"{searchValue}"</Typography>
                          </Box>
                        } 
                      />
                    </ListItemButton>
                  </ListItem>
                </Box>
              )}
              {loading && (
                <ListItem disablePadding>
                  <CabSpinner />
                </ListItem>
              )}

            </List>
          </Box>
        )}
      </CabModal>
    </>
  );
};

export default AdditionalCalendars;
