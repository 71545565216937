import { ReactElement } from 'react';
import { IoWarning } from 'react-icons/io5';
import { DateTime } from 'luxon';
import { MeetingSlot } from '../../../store';
import { currentMeetingFilter, getLocalTimeZoneName, getSlotDuration, TimeZone } from '../../../utils/scheduleUtils';
import { formatSlot } from '../../../utils/datesUtils';
import { Box, Tooltip, Typography } from '@mui/material';
import { CabIcon, CabList } from '@CabComponents';
import { MeetingSettingFormInput } from './MeetingSettings';
import { Control, useWatch } from 'react-hook-form';

interface Props {
  selectedSlots: MeetingSlot[];
  currentMeetingId: number | undefined;
  handleDeleteSlots: (slots: MeetingSlot[]) => void;
  timezone: TimeZone | undefined;
  readonly: boolean;
  control: Control<MeetingSettingFormInput>
}

const TimeSlotChips = ({ selectedSlots, currentMeetingId, handleDeleteSlots, timezone,
  readonly, control
} : Props): ReactElement => {
  
  const meetingDurationMinutes = useWatch({control, name: "duration"});
  
  const filteredSlots =  selectedSlots.slice().sort((slotA, slotB) =>  {
    const slotATimeStamp = DateTime.fromISO(slotA.start_date).toMillis();
    const slotBTimeStamp = DateTime.fromISO(slotB.start_date).toMillis();
    return slotATimeStamp - slotBTimeStamp;
  }).filter(slot => currentMeetingFilter(slot, currentMeetingId) && !slot.is_exclude);

  const daySlots: Record<string, MeetingSlot[]> = {};
  filteredSlots.forEach(slot => {
    const day = DateTime.fromISO(slot.start_date).setZone(timezone?.name || "").toFormat("ccc M/d/yyyy");

    if (!daySlots[day]) daySlots[day] = [];

    daySlots[day].push(slot);
  });

  return (
    <>
      {Object.keys(daySlots).map((day) => (
        <div key={day}>
          <Typography variant="h6" sx={{fontSize: "0.875em", marginTop: 1}}>{day}</Typography>

          <CabList
            items={daySlots[day].map((slot) => {
              const startDate = new Date(slot.start_date || '');
              const endDate = new Date(slot.end_date || '');
              const dateString = formatSlot(startDate, endDate, timezone?.name || getLocalTimeZoneName());
              const showDurationWarning = meetingDurationMinutes != null
                ? (!!currentMeetingId) && getSlotDuration(slot) < meetingDurationMinutes
                : false;

              return {
                value: slot.id,
                label: (
                  <Box display="flex" justifyContent="space-between">
                    <span>{dateString}</span>
                    {showDurationWarning && (
                      <Tooltip
                        title={<span>
                          Time slot is shorter than the meeting duration
                        </span>}
                        placement="top-start"
                      >
                        <div>
                          <CabIcon
                            Icon={IoWarning}
                            color="warning"
                            alt=''
                            sx={{ marginTop: '2px', fontSize: 18 }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </Box>
                ),
                icon: <span>{DateTime.fromJSDate(startDate, { zone: timezone?.name }).toFormat('ZZZZ')}</span>,
                editable: !readonly,
                onDeleteClick: () => handleDeleteSlots([slot])
              };
            })}
          />
        </div>
      ))}
    </>
  );
};

export default TimeSlotChips;
