import { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, Meeting, RootState, ThunkDispatchType, Leader } from "../../../store";
import { getLeadersForScheduling } from "../../../utils/leaderUtils";
import ScheduleHeader from "./ScheduleHeader";


export interface ScheduleHeaderProps {
  currentMeeting: Meeting|null;
  selectedLeaders: number[];
  creatingMeeting: boolean;
  onCancel: () => void;
  onLeaderSelect: (ids: number[]) => void;
  opencalendarsModal: () => void;
  onCreateMeeting: () => void;
  onCreateReusableMeeting: () => void;
  onCreatePoll: () => void;
}


export const ScheduleHeaderContainer = ({
  selectedLeaders, onLeaderSelect, opencalendarsModal, onCancel, creatingMeeting, currentMeeting,
  onCreateMeeting, onCreateReusableMeeting, onCreatePoll,
}: ScheduleHeaderProps): ReactElement | null => {
  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);

  const dispatch = useDispatch<ThunkDispatchType>();

  const handleUpdateLeaders = async (ldrs: Leader[]) => {
    return dispatch(actions.leaders.updateLeaders(ldrs));
  };

  const leadersForScheduling = getLeadersForScheduling(leaders.leaders);

  if (!user) return null;

  return (
    <ScheduleHeader
      user={user}
      selectedLeaders={selectedLeaders}
      onUpdateLeaders={handleUpdateLeaders}
      leadersForScheduling={leadersForScheduling}
      calendars={calendars}
      creatingMeeting={creatingMeeting}
      currentMeetingId={currentMeeting?.id}
      onCancel={onCancel}
      onLeaderSelect={onLeaderSelect}
      execPickerDisabled={!!currentMeeting && currentMeeting.create_user?.id !== user.id}
      opencalendarsModal={opencalendarsModal}
      onCreateMeeting={onCreateMeeting}
      onCreateReusableMeeting={onCreateReusableMeeting}
      onCreatePoll={onCreatePoll}
    />
  );
};
