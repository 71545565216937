import { CabButton } from "@CabComponents/CabButton";
import { CabControlLabel } from "@CabComponents/CabControlLabel";
import { CabModal } from "@CabComponents/CabModal";
import { CabSwitch } from "@CabComponents/CabSwitch";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { Box, FormLabel, IconButton, FormControl } from "@mui/material";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actions, MeetingQuestion, MeetingQuestionOption, ThunkDispatchType } from "../../../store";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { QUESTION_TYPE } from "../../../constants";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoAdd, IoTrashOutline } from "react-icons/io5";


export type templateFields = { title: string, description?: string, locations?: string[] | null; };
export interface QuestionModalProps {
  open: boolean;
  questionId?: MeetingQuestion['id'];
  onClose: () => void;
  questions: {[id: number]: MeetingQuestion};
  currentMeetingId: number | undefined;
  isPoll: boolean;
  forceRequired?: boolean;
  meetingField?: templateFields;
  hasSingleSelectOption?: boolean;
}

const QuestionModal = ({ 
  open, questionId, questions, onClose, currentMeetingId, isPoll, forceRequired, meetingField, hasSingleSelectOption
} : QuestionModalProps): ReactElement => {
  const [title, setTitle] = useState('');
  const [required, setRequired] = useState(false);
  const [limitedPermision, setLimitedPermision] = useState(false);
  const [questionType, setQuestionType] = useState<keyof typeof QUESTION_TYPE>(1);
  const nextOrder = Math.max(0, ...Object.values(questions).map(q => q.order)) + 1;

  const dispatch = useDispatch<ThunkDispatchType>();


  const question = questionId ? questions[questionId] : null;
  const meeting = currentMeetingId ?? -1;
  const order = question?.order ?? nextOrder;
  const meetingType = isPoll ? 'Poll' : 'Meeting';

  const defaultAnswerOption: MeetingQuestionOption = {id: -1, question: questionId ?? -1,  name: '' };

  const { control, reset, formState:{ isValid } } = useForm<{answerOptions: MeetingQuestionOption[]}>({
    defaultValues: {'answerOptions': questionId && question?.options ? question.options : [defaultAnswerOption] }
  });

  const { fields, append, remove } = useFieldArray({control, name: 'answerOptions'});

  const answerOptions = useWatch({name: 'answerOptions', control});

  const setPermission = useCallback(() => {
    setLimitedPermision(false);
    if (meetingField && questionId) {
      if (meetingField.title.includes(`{{answer_${questionId}}}`) || 
      meetingField.title.includes(`{{question_${questionId}}}`)) {
        setLimitedPermision(true);
        return;
      }
      if (meetingField.description) {
        if (meetingField.description.includes(`{{answer_${questionId}}}`) || 
      meetingField.description.includes(`{{question_${questionId}}}`)) {
          setLimitedPermision(true);
          return;
        }
      }
      if (meetingField.locations) {
        meetingField.locations.forEach(location => {
          if (location.includes(`{{answer_${questionId}}}`) || 
          location.includes(`{{question_${questionId}}}`)) {
            setLimitedPermision(true);
            return;
          }
        });
      }
    }
  }, [meetingField, questionId]);

  useEffect(() => {
    if (open && question) {
      setTitle(question.title);
      setRequired(question.required);
      setQuestionType(question.question_type);
      setPermission();
    } else if (open && !question && forceRequired) {
      setRequired(true);
    }
  }, [question, open, forceRequired, setPermission]);

  const clearData = () => {
    setTitle('');
    setRequired(false);
  };

  const handleCreateQuestion = (newQuestions: Omit<MeetingQuestion, 'id'>[], meetingId: number) => {
    try {
      dispatch(actions.schedule.createQuestion(newQuestions, meetingId, meetingId > 0));
    } catch (err) {
      alert(`Error: Question could not be created. Please try again
          later or email support@joincabinet.com for assistance.`);
    }
  };

  const handleUpdateQuestion = (updatedQuestions: MeetingQuestion) => {
    try {
      dispatch(actions.schedule.updateQuestion(updatedQuestions, meeting > 0));
    } catch (err) {
      alert(`Error: Question could not be updated. Please try again
          later or email support@joincabinet.com for assistance.`);
    }
  };

  const handleDeleteQuestion = () => {
    if (question == null) return;

    try {
      dispatch(actions.schedule.deleteQuestion(question, meeting, meeting > 0));
      clearData();
      onClose();
    } catch (err) {
      alert(`Error: Question could not be deleted. Please try again
          later or email support@joincabinet.com for assistance.`);
    }
  };

  const handleSaveQuestion = () => {
    const questionObj = {
      meeting,
      title: title,
      required: forceRequired ? true : required,
      per_timeslot: false,
      question_type: questionType,
      order: order,
      options: questionType > 1 ? answerOptions : [],
    };

    if (!questionId) {
      handleCreateQuestion([questionObj], meeting);
    } else {
      handleUpdateQuestion({ ...questionObj, id: questionId });
    }

    clearData();
    onClose();
  };

  const handleCancelQuestion = () => {
    clearData();
    reset();
    setQuestionType(1);
    onClose();
  };
  
  return (
    <CabModal
      open={open}
      onClose={handleCancelQuestion}
      title={questionId ? `Edit ${meetingType} Question` : `Create ${meetingType} Question`}
      closeIcon
      actionButtons={
        <Box display='flex' justifyContent='space-between' width='100%'>
          <Box>
            {questionId && (
              <CabTooltip wrapWithSpan title={limitedPermision ? 
                'This question is being used as a template variable, it cannot be deleted' : ''}>
                <CabButton buttonType="primary" onClick={handleDeleteQuestion} disabled={limitedPermision}>
                  Delete
                </CabButton>
              </CabTooltip>
            )}
          </Box>
          <Box>
            <CabButton buttonType="tertiary" sx={{ marginRight: 1 }} onClick={handleCancelQuestion}>
              Cancel
            </CabButton>
            <CabButton buttonType="primary" disabled={!title || (!isValid && questionType > 1)} 
              onClick={() => handleSaveQuestion()}>
              Save
            </CabButton>
          </Box>
        </Box>
      }
    >
      <>
        <FormControl sx={{ width: '100%'}}>
          <FormLabel>
            Question
          </FormLabel>
          <CabTextInput
            fullWidth
            placeholder={ isPoll ? "Enter a question for your participants" : "Enter a question for your respondents" }
            maxRows={4}
            minRows={1}
            value={title}
            onChange={(e) => setTitle(e.target.value || '')}
            multiline
            sx={{marginBottom: 1}}
          />
        </FormControl>
        {hasSingleSelectOption && (
          <FormControl sx={{ width: '100%'}}>
            <FormLabel>
              Answer Type
            </FormLabel>
            <CabDropdown
              value={Object.keys(QUESTION_TYPE).find(key => Number(key) === questionType)} 
              placeholder="Select Question Type"
              disabled={!!questionId}
              options={Object.keys(QUESTION_TYPE).map((questionTypeId) => ({
                value: questionTypeId,
                label: QUESTION_TYPE[Number(questionTypeId)],
              }))}
              onChange={(e) => setQuestionType(Number(e.target.value))}
              sx={{marginBottom: 1}}
            />
          </FormControl>
        )}
        <CabTooltip wrapWithSpan title={forceRequired ? 'Questions must be required to be used in fields' 
          : limitedPermision ? 'This question is being used as a template variable, it must remain required' : ''}>
          <CabControlLabel
            label="Required"
            disabled={forceRequired || limitedPermision}
            labelPlacement='start'
            // sx={{display: 'block'}}
            control={<CabSwitch checked={required} onChange={() => {
              setRequired(!required);
            }} />}
          />
        </CabTooltip>
        {questionType > 1 && (
          <Box width='100%' display='flex' flexDirection='column'>
            <form>
              <Box width='100%' display='flex' flexDirection='column' marginTop={1}>
                {fields.map((answerOption, index) => {
                  return (
                    <Box key={answerOption.id} display='flex' flexDirection='column'>
                      <Controller
                        name={`answerOptions.${index}.name`}
                        defaultValue={`${answerOption.name}`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                          <Box display='flex' flexDirection='column' gap={1}>
                            <FormLabel htmlFor="answerInput">{index === 0 ? 'Options' : undefined}</FormLabel>
                            <Box display='flex' width='100%'>
                              <CabTextInput
                                {...field}
                                inputProps={{ id: 'answerInput'}}
                                inputRef={ref}
                                fullWidth
                                required
                                sx={{maxWidth: 355}}
                              />
                              {index !== 0 && 
                                <IconButton aria-label='remove' disableRipple sx={{padding: 0}}
                                  onClick={() => remove(index)}
                                >
                                  <CabIcon Icon={IoTrashOutline} alt='Remove' />
                                </IconButton>
                              }
                            </Box>
                          </Box>
                        )}/>  
                    </Box>
                  );
                })}
                <Box marginTop={2}>
                  <CabButton
                    buttonType="tertiary"
                    color='accent'
                    onClick={() => append(defaultAnswerOption)}
                    icon={<CabIcon Icon={IoAdd} alt='Add'/>}
                  >
                    Add Answer Option
                  </CabButton>
                </Box>
              </Box>
            </form>
          </Box>
        )}
      </>
    </CabModal>
  );
};

export default QuestionModal;